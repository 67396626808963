import React from "react";
import NavigationComponent from "../components/NavigationComponent";
import Pagination from "../components/Pagination";
import {HostContext} from "../App";
import {Link} from "react-router-dom";
import FooterComponent from "../components/FooterComponent";
import ReactGA from "react-ga";
import ExpositionCardComponent from "../components/ExpositionCardComponent";
import IntlMessages from "../util/IntlMessages";
import {injectIntl} from "react-intl";
import {ReactSVG} from "react-svg";
import { css } from 'glamor'
import Sticky from "react-stickynode";

// todo remplacer ExpositionsPage, ExpositionsUpcomingPage et ExpositionsEndedPage par une seul page avec paramètre
class ExpositionsPage extends React.Component {

    validStatus = ["current", "ended", "upcoming"];
    subTitles = {
        current: "Expositions en cours",
        ended: "Expositions terminées",
        upcoming: "Expositions à venir"
    }

    state = {
        status : "current",
        selectedPageSize: 10,
        currentPage: 1,
        totalPage: 0,
        items: [],
        totalItemCount: 0,
        isLoading: false,
    };

    constructor(props) {
        super(props);

        const {messages} = props.intl;
        this.subTitles['current'] = messages['exhibitions.current'];
        this.subTitles['ended'] = messages['exhibitions.finished'];
        this.subTitles['upcoming'] = messages['exhibitions.upcoming'];

        if (typeof(props.match.params.status) !== "undefined") {
            if (this.validStatus.includes(props.match.params.status)) {
                this.state.status = props.match.params.status;
            } else {
                props.history.push('/error')
            }
        } else {
            this.state.status = "current";
        }
    }

    componentDidMount = () => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    };

    componentWillMount = () => {
        this.dataListRender();
    };

    componentWillReceiveProps(nextProps) {
        window.scrollTo(0, 0);
        if (typeof(nextProps.match.params.status) !== "undefined") {
            if (this.validStatus.includes(nextProps.match.params.status)) {
                this.setState({
                    status: nextProps.match.params.status
                }, () => {
                    this.dataListRender()
                })
            } else {
                this.props.history.push('/error')
            }
        } else {
            this.setState({
                status: "current"
            }, () => {
                this.dataListRender()
            })
        }
    }

    dataListRender = () => {
        let totalPage = 0;
        let totalItemCount = 0;
        fetch(HostContext.hostName + '/expositions?selection=' + this.state.status
            + '&pageSize=' + this.state.selectedPageSize.toString()
            + '&currentPage=' + this.state.currentPage.toString())
            .then(response => {
                totalItemCount = response.headers.get('X-Pagination-Total-Count');
                totalPage = response.headers.get('X-Pagination-Page-Count');
                return response.json()
            }, function (error) {
                console.log(error.message); //=> String
            }).then(json => {
            console.log(json);
            this.setState({
                totalPage: totalPage,
                items: json,
                totalItemCount: totalItemCount,
                isLoading: true
            });
        });
    };


    renderExpositions = () => {
        if (this.state.items.length < 1 ) {
            const styles = css({
                ' svg': {
                    height: 400,
                },
            });

            switch (this.state.status) {
                case "current":
                    return (
                        <div className="container">
                            <div className="row border-top pt-20 text-center">
                                <div className="col-sm-12 mb-50">
                                    <IntlMessages id="exhibitions.current-none"/>
                                </div>
                                <div className="col-sm-12">
                                    <ReactSVG src="/style/images/art/photographer.svg" {...styles} />
                                </div>
                            </div>
                        </div>
                    )
                case "upcoming":
                    return (
                        <div className="container">
                            <div className="row mb-50 border-top pt-20 text-center">
                                <div className="col-sm-12 mb-50">
                                    <IntlMessages id="exhibitions.upcoming-none"/>
                                </div>
                                <div className="col-sm-12">
                                    <ReactSVG src="/style/images/art/photographer.svg" {...styles} />
                                </div>
                            </div>
                        </div>
                    )
                default:
                    return (
                        <div className="container">
                            <div className="row mb-50 border-top pt-20 text-center">
                                <div className="col-sm-12 mb-50">
                                    <IntlMessages id="exhibitions.finished-none"/>
                                </div>
                                <div className="col-sm-12">
                                    <ReactSVG src="/style/images/art/photographer.svg" {...styles} />
                                </div>
                            </div>
                        </div>
                    )
            }
        }

        return (
            <div className="container">
                <div className="blog grid grid-view boxed">
                    <div className="row">
                        {this.state.items.map((exposition, index) => (
                            <ExpositionCardComponent key={index} exposition={exposition}/>
                        ))}
                    </div>
                </div>
            </div>
        )
    };

    render = () => {
        return (
            <div className="content-wrapper">
                <Sticky enabled={true} innerZ={999999}>
                    <NavigationComponent/>
                </Sticky>

                <div className="wrapper white-wrapper">
                    <div className="container inner pt-70">
                        <h1 className="heading text-center">{this.subTitles[this.state.status]}</h1>
                        <div className="space50"></div>
                        {this.renderExpositions()}
                    </div>

                    <Pagination
                        currentPage={this.state.currentPage}
                        totalPage={this.state.totalPage}
                        onChangePage={i => this.onChangePage(i)}
                    />
                </div>

                <div className="wrapper bg-pastel-default">
                    <div className="container inner text-center">
                        <h2 className="sub-heading text-center"><IntlMessages id="exhibitions.search-around"/></h2>
                        <div className="space10"></div>
                        <Link to="/expositions/search" className="btn btn-white shadow"><IntlMessages id="general.search"/></Link>
                    </div>
                </div>

                <FooterComponent/>

            </div>
        )
    };
}

export default injectIntl(ExpositionsPage);