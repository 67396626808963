import React from "react";
import {Link, NavLink} from "react-router-dom";
import {HostContext} from "../App";
import NavigationComponent from "../components/NavigationComponent";
import Moment from "moment";
import FooterComponent from "../components/FooterComponent";
import ReactGA from "react-ga";
import IntlMessages from "../util/IntlMessages";
import {injectIntl} from "react-intl";
import Sticky from "react-stickynode";

class ExpositionsResultPage extends React.Component {

    state = {
        subTitle: "",
        expositions: [],
        isLoading: true,
    };

    componentDidMount = () => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    };

    componentWillMount = () => {
        const {messages} = this.props.intl;
        const params = new URLSearchParams(this.props.location.search);
        let url = "";
        if (params.get('text') != null) {
            const text = params.get('text');
            url = HostContext.hostName + '/expositions/search?text=' + text;
            if ((text.length === 5) && Number.isInteger(parseInt(text, 10))) {
                this.setState({
                    subTitle: messages["exhibitions.result-postal-code"] + " " + text
                })
            } else {
                this.setState({
                    subTitle: messages["exhibitions.result-city"] + " " + text
                })
            }
        } else if (params.get('dept') != null) {
            url = HostContext.hostName + '/expositions/search?dept=' + params.get('dept');
            this.setState({
                subTitle: "Résultats à proximité du département : " + params.get('dept')
            })
        } else {

        }

        fetch(url)
            .then(response => response.json())
            .then(json => {
                console.log(json);
                this.setState({
                    expositions: json,
                    isLoading: false
                });
            });
    };

    renderResult = () => {
        if (this.state.expositions.length < 1) {
            return (
                <div className="wrapper white-wrapper">
                    <div className="inner pt-70">
                        <div className="container">
                            <h1 className="heading text-center"><IntlMessages id="exhibitions.search"/></h1>
                            <h2 className="sub-heading2 text-center">{this.state.subTitle}</h2>
                        </div>
                        <div className="space50"></div>
                        <div className="clearfix"></div>
                        <div className="container">
                            <div className="row mb-50 border-top pt-20">
                                <div className="col-12">
                                    <div className="text-center">
                                        <p className="font-weight-bold"><IntlMessages id="exhibitions.search-none"/></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="wrapper white-wrapper">
                <div className="inner pt-70">
                    <div className="container">
                        <h1 className="heading text-center"><IntlMessages id="exhibitions.search"/></h1>
                        <h2 className="sub-heading2 text-center">{this.state.subTitle}</h2>
                    </div>
                    <div className="space50"></div>
                    <div className="clearfix"></div>
                    <div className="container">
                        <div className="row mb-50 border-top pt-20">
                            {this.state.expositions.map((exposition, index) => (
                                <div className="col-sm-6">
                                    <div className="d-flex flex-column flex-md-row justify-content-start">
                                        <figure className="rounded">
                                            <img src={exposition.media.url} width="300" className="radius" alt=""/>
                                        </figure>
                                        <div className="space30 d-md-none"></div>
                                        <div className="ml-50">
                                            <h3>{exposition.name}</h3>
                                            <div className="meta">
                                                <span className="date">
                                                    {Moment(exposition.start).format('D MMM YYYY')}
                                                    &nbsp;-&nbsp;
                                                    {Moment(exposition.finish).format('D MMM YYYY')}
                                                </span>
                                            </div>
                                            <p>{exposition.place.name}<br/>
                                            {exposition.place.postal_code} {exposition.place.city}</p>
                                            <div className="space10"></div>
                                            <NavLink className="cbp-singlePageInline btn btn-white shadow"
                                                to={`/expositions/${exposition.name_url}/${exposition.id}`}>
                                                Voir l'exposition
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    render() {
        return (
            <div>
                <Sticky enabled={true} innerZ={999999}>
                    <NavigationComponent/>
                </Sticky>

                <div className="space60" />
                {this.renderResult()}

                <div className="wrapper gray-wrapper">
                    <div className="container inner text-center">
                        <h2 className="sub-heading text-center"><IntlMessages id="exhibitions.new-search"/></h2>
                        <div className="space10"></div>
                        <Link to="/expositions/search" className="btn btn-white shadow"><IntlMessages id="general.search"/></Link>
                    </div>
                </div>

                <FooterComponent/>
            </div>
        )
    }
}

export default injectIntl(ExpositionsResultPage);
