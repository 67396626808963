import React from "react";
import {withRouter} from "react-router-dom";
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "reactstrap";
import Moment from 'moment';
import {FormattedMessage, injectIntl} from "react-intl";
import IntlMessages from "../util/IntlMessages";
import {HostContext} from "../App";
import {Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import PropTypes from "prop-types";
import PrivacyComponent from "../components/PrivacyComponent";
import { css } from 'glamor';
import { toast } from 'react-toastify';

class SignupForm extends React.Component {

    state = {
        modalPrivacyOpen: false,
        modalTermsOpen: false,
        generalError: ""
    };

    constructor(props) {
        super(props);
        this.state = this.props.state;
    }

    handleSubmit = async (values, {setErrors}) => {
        // 2000-07-01T00:00:00+00:00
        console.log(Moment.utc(new Date()).format("YYYY-MM-DDThh:mm:ssZ"));
        values["utc_time"] = Moment.utc(new Date()).format("YYYY-MM-DDThh:mm:ssZ");
        console.log(values);

        let url = HostContext.hostName + '/users/signup';

        const that = this;

        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(values)
        }).then(function(response) {
            const statusCode = response.status;
            const data = response.json();
            return Promise.all([statusCode, data]);
        }).then(function([statusCode, data]) {
            switch (statusCode) {
                case 200:
                    that.props.toggle(true);
                    const {messages} = that.props.intl;
                    toast(messages["registration.confirmed"], {
                        autoClose: false,
                        className: css({
                            color: "white !important",
                            background: "#6DC3AB !important"
                        }),
                    });
                    /*
                    UserContext.isLogged = true;
                    UserContext.access_token = data.access_token;
                    UserContext.mapkit_token = data.mapkit_token;
                    UserContext.user = data.user;
                    const userInfo = {
                        access_token: UserContext.access_token,
                        mapkit_token: UserContext.mapkit_token,
                        user: UserContext.user,
                    }
                    ls.set('zimbel-backend-user', userInfo);
                    that.props.history.push('/');
                    */
                    break;
                case 422:
                    data.forEach(function(element) {
                        setErrors({ [element.field]: element.message });
                    });
                    break;
                default:
                    setErrors({ 'general_error': 'Status code ' + statusCode });
                    break;
            }
        }).catch(function(error) {
            setErrors({ 'general_error': error.message });
        });


/*
        }).then(response => {
            console.log(response);
            if (response.ok) {
                return response.json()
            }
            switch (response.status) {
                case 401:
                    throw Error(response.statusText);
                default:
                    throw Error(response.statusText);
            }
        }).then(json => {
            console.log(json);
            this.props.toggle(true);
            //this.dataListRender();
            //this.toggleModal();
        }).catch(function (error) {
            console.log(error);
            that.setState({'generalError': error.message});
        });
 */
    };

    togglePrivacyModal = () => {
        this.setState({
            modalPrivacyOpen: !this.state.modalPrivacyOpen
        });
    };

    toggleTermsModal = () => {
        this.setState({
            modalTermsOpen: !this.state.modalTermsOpen
        });
    };

    render = () => {
        const {messages} = this.props.intl;

        const SignupSchema = Yup.object().shape({
            email: Yup.string().email(messages['general.email-invalid']).required(messages['general.field-required']),
            terms: Yup.boolean().oneOf([true], 'Must Accept Terms and Conditions'),
        });

        return (
            <Formik
                initialValues={{
                    email: this.state.currentItem != null ? this.state.currentItem.email : "",
                    terms: false
                }}
                validationSchema={SignupSchema}
                onSubmit={this.handleSubmit}
            >
                {({ errors, touched, isValid }) => (
                    <Form>
                        <ModalHeader toggle={this.props.toggle}>
                            <IntlMessages id="user.signup-title"/>
                        </ModalHeader>

                        <ModalBody>

                            <div className="col-md-12 pr-10">
                                <div className="form-group text-center">
                                    <IntlMessages id="user.signup-message"/>
                                </div>
                            </div>

                            <div className="col-md-12 pr-10">
                                <div className="form-group color-red">
                                    <Field type="email"
                                           name="email"
                                           className="form-control"
                                           placeholder={messages["user.email"]}
                                    />
                                    <ErrorMessage name="email" />
                                </div>
                            </div>

                            <div className="col-md-12 pr-10">
                                <div className="form-group">
                                    <div className="custom-control custom-checkbox">
                                        <Field type="checkbox" name="terms" className="custom-control-input" id="customCheck1" />
                                            <label className="custom-control-label" htmlFor="customCheck1">
                                                <FormattedMessage
                                                    id="user.signup-agreement"
                                                    values={{
                                                        privacy: <a href="##" onClick={this.togglePrivacyModal}>{messages['user.signup-privacy']}</a>,
                                                        terms: <a href="##" onClick={this.toggleTermsModal}>{messages['user.signup-terms']}</a>,
                                                    }}
                                                />
                                            </label>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 pr-10 color-red">
                                {this.state.generalError}
                            </div>


                            <Modal
                                isOpen={this.state.modalPrivacyOpen}
                                size="lg"
                                style={{top: 100}}
                            >
                                <ModalHeader toggle={this.togglePrivacyModal}>
                                    <FormattedMessage id="user.signup-privacy-title" />
                                </ModalHeader>
                                <ModalBody>
                                    <PrivacyComponent />
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="primary" onClick={this.togglePrivacyModal}>
                                        <FormattedMessage id="general.close" />
                                    </Button>
                                </ModalFooter>
                            </Modal>

                            <Modal isOpen={this.state.modalTermsOpen}>
                                <ModalHeader toggle={this.toggleTermsModal}>
                                    <FormattedMessage id="user.signup-terms-title" />
                                </ModalHeader>
                                <ModalBody>Stuff and things</ModalBody>
                                <ModalFooter>
                                    <Button color="primary" onClick={this.toggleTermsModal}>
                                        <FormattedMessage id="general.close" />
                                    </Button>
                                </ModalFooter>
                            </Modal>

                        </ModalBody>

                        <ModalFooter>
                            <button
                                className="btn btn-pastel-orange"
                                onClick={this.props.toggle}
                            >
                                <IntlMessages id="general.cancel"/>
                            </button>
                            <button className="btn" type="submit" disabled={!isValid || !touched}>
                                <IntlMessages id="general.submit"/>
                            </button>{" "}
                        </ModalFooter>

                    </Form>


                )}
            </Formik>
        )
    }
}

SignupForm.propTypes = {
    state: PropTypes.object.isRequired,
    toggle: PropTypes.func.isRequired,
};

export default withRouter(injectIntl(SignupForm));
