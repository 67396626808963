import React from "react";
import {Link} from "react-router-dom";

class AlphaPagination extends React.Component {

    componentDidMount() {}

    onChangePage(e) {
        this.props.onChangePage(e);
    }

    render = () => {
        const {
            currentPage = "A",
        } = this.props;

        const points = [];
        for (let i = 65; i <= 90; i++) {
            points.push(String.fromCharCode(i));
        }

        return (

            <div className="pagination bg text-center mb-0">
                <ul className="social social-bg social-bg-color">

                    {points.map((chr, index) => {
                        return (
                            <li key={index} className={`${currentPage === chr && "active"}`}>
                                <Link to="#" className="bg-orange mb-10">
                                    <div className="color-white shadow" onClick={() => this.onChangePage(chr)}>
                                        <span>{chr}</span>
                                    </div>
                                </Link>
                            </li>
                        );
                    })}


                </ul>
            </div>
        )
    };

}


export default AlphaPagination;
