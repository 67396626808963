import React from "react";
//import { Colxx } from "../components/CustomBootstrap";
//import { Nav, NavItem, NavLink } from "reactstrap";

class Pagination extends React.Component {

    componentDidMount() {}

    onChangePage(e) {
        this.props.onChangePage(e);
    }

    render = () => {
        const {
            totalPage = 0,
            currentPage = 1,
            numberLimit = 5,
            lastIsActive = true,
            firstIsActive = true
        } = this.props;

        let startPoint = 1;
        let endPoint = numberLimit;

        if (numberLimit > totalPage) {
            startPoint = 1;
            endPoint = totalPage;
        } else if (currentPage <= parseInt(numberLimit / 2, 10)) {
            startPoint = 1;
            endPoint = numberLimit;
        } else if (currentPage + parseInt(numberLimit / 2, 10) <= totalPage) {
            startPoint = currentPage - parseInt(numberLimit / 2, 10);
            endPoint = currentPage + parseInt(numberLimit / 2, 10);
        } else {
            startPoint = totalPage - (numberLimit - 1);
            endPoint = totalPage;
        }
        startPoint = startPoint === 0 ? 1 : startPoint;
        const points = [];
        for (var i = startPoint; i <= endPoint; i++) {
            points.push(i);
        }

        let firstPageButtonClassName = currentPage <= 1 ? "btn btn-circle shadow disabled" : "btn btn-circle shadow";
        let lastPageButtonClassName = currentPage >= totalPage ? "btn btn-circle shadow disabled" : "btn btn-circle shadow";
        let prevPageButtonClassName = currentPage <= 1 ? "btn btn-circle shadow disabled" : "btn btn-circle shadow";
        let nextPageButtonClassName = currentPage >= totalPage ? "btn btn-circle shadow disabled" : "btn btn-circle shadow";

        return totalPage > 1 ? (

            <div className="pagination bg text-center mb-0">
                <ul>
                    {firstIsActive && (
                        <li>
                            <button className={firstPageButtonClassName} onClick={() => this.onChangePage(1)}>
                                <i className="si si-arrows_double-arrow-left"></i>
                            </button>
                        </li>
                    )}

                    <li>
                        <button className={prevPageButtonClassName} onClick={() => this.onChangePage(currentPage - 1)}>
                            <i className="mi-arrow-left"></i>
                        </button>
                    </li>

                    {points.map(i => {
                        return (
                            <li key={i} className={`${currentPage === i && "active"}`}>
                                <button className="btn btn-circle shadow" onClick={() => this.onChangePage(i)}>
                                    <span>{i}</span>
                                </button>
                            </li>
                        );
                    })}

                    <li>
                        <button className={nextPageButtonClassName} onClick={() => this.onChangePage(currentPage + 1)}>
                            <i className="mi-arrow-right"></i>
                        </button>
                    </li>

                    {lastIsActive && (
                        <li>
                            <button className={lastPageButtonClassName} onClick={() => this.onChangePage(totalPage)}>
                                <i className="si si-arrows_double-arrow-right"></i>
                            </button>
                        </li>
                    )}

                </ul>
            </div>
        ) : (
            <div></div>
        )
    };

}


export default Pagination;
