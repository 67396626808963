import React from "react";
import {NavLink} from "react-router-dom";
import Cookies from 'universal-cookie';
import CookieConsent from "react-cookie-consent";
import IntlMessages from "../util/IntlMessages";
import {injectIntl} from "react-intl";

class FooterComponent extends React.Component {

    state = {
        statistics: true,
    };

    changeStatistics = () => {
        this.setState({
            statistics: !this.state.statistics
        })
    };

    render() {
        const {messages} = this.props.intl;

        return (

            <footer className="dark-wrapper inverse-text">
                <div className="container inner">
                    <div className="row">
                        <div className="col-md-6 col-lg-4">
                            <div className="widget">
                                <h3 className="widget-title"><IntlMessages id="footer.learn-more"/></h3>
                                <ul className="list-unstyled">
                                    <li><NavLink to={`/about-us`} className="nocolor"><IntlMessages id="footer.about-us"/></NavLink></li>
                                    <li><NavLink to={`/privacy`} className="nocolor"><IntlMessages id="footer.privacy-policy"/></NavLink></li>
                                    <li><NavLink to={`/our-projects`} className="nocolor"><IntlMessages id="footer.our-projects"/></NavLink></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="widget">
                                <h3 className="widget-title"><IntlMessages id="footer.need-help"/></h3>
                                <ul className="list-unstyled">
                                    <li><NavLink to={`/support`} className="nocolor"><IntlMessages id="footer.support"/></NavLink></li>
                                    <li><NavLink to={`/how-to-start`} className="nocolor"><IntlMessages id="footer.how-to-start"/></NavLink></li>
                                    <li><NavLink to={`/contact-us`} className="nocolor"><IntlMessages id="footer.contact-us"/></NavLink></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="widget">
                                <h3 className="widget-title"><IntlMessages id="footer.elsewhere"/></h3>
                                <ul className="social social-mute social-s mt-10 mb-0">
                                    <li><i className="fa fa-twitter"></i></li>
                                    <li><i className="fa fa-facebook-f"></i></li>
                                    <li><i className="fa fa-pinterest"></i></li>
                                    <li><i className="fa fa-vimeo"></i></li>
                                    <li><i className="fa fa-instagram"></i></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="space30"></div>
                    <p className="text-center mb-0"><IntlMessages id="footer.copyright"/></p>
                </div>

                <CookieConsent
                    location="bottom"
                    buttonText={messages["cookie.accept"]}
                    cookieName="cookie_accepted"
                    style={{ background: "#faf1ed", color: "#000000" }}
                    buttonStyle={{
                        background: "#fb9d7e",
                        color: "#FFFFFF",
                        fontSize: "13px",
                        fontWeight: "bold",
                        borderRadius: 50,
                        paddingLeft: 20,
                        paddingRight: 20,
                        textTransform: "uppercase"
                    }}
                    expires={365}
                    acceptOnScroll={false}
                    onAccept={() => {
                        const cookies = new Cookies();
                        cookies.set('cookie_statistics', true, { path: '/', maxAge: 60*60*24*365 });
                        alert("Accept was triggered by clicking the Accept button");
                    }}
                >
                    <IntlMessages id="cookie.message"/>{" "}

                    <div className="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" className="custom-control-input" id="customCheckDisabled" defaultChecked={true} disabled={true} />
                        <label className="custom-control-label cookie-text" htmlFor="customCheckDisabled"><IntlMessages id="cookie.necessary"/></label>
                    </div>
                    <div className="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" className="custom-control-input" id="customRadioDisabled" checked={this.state.statistics} onChange={this.changeStatistics} />
                        <label className="custom-control-label cookie-text" htmlFor="customRadioDisabled"><IntlMessages id="cookie.statistics"/></label>
                    </div>
                    <div>
                        <NavLink to={`/support`} className=""><IntlMessages id="cookie.privacy-policy"/></NavLink>
                    </div>
                </CookieConsent>
            </footer>

        )
    }
}

FooterComponent.propTypes = {
    //exposition: PropTypes.object.isRequired,
};

export default injectIntl(FooterComponent);

/*
position: fixed; top: 0px; left: 0px; right: 0px; z-index: 100000; background-color: rgb(248, 247, 247); padding: 10px;


min-height: 32px; font-size: 10pt; font-weight: 400; line-height: 130%; padding: 10px 0px; color: rgb(0, 0, 0);"

This website uses cookies to enhance the user experience.
 */