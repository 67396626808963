import React from "react";
import PropTypes from "prop-types";
import Moment from 'moment';
import 'moment/locale/fr';
import {NavLink} from "react-router-dom";

class PhotographerCardComponent extends React.Component {

    render() {
        Moment.locale('fr');
        return (

            <div className="item grid-sizer col-md-6 col-lg-4">
                <div className="box bg-white shadow p-30">
                    <figure className="main mb-20 overlay overlay1 rounded">
                        <NavLink to={`photographers/${this.props.photographer.name_url}/${this.props.photographer.id}`}>
                            <span className="bg"></span>
                            <img src={this.props.photographer.media.url} alt=""/>
                        </NavLink>
                        <figcaption>
                            <h5 className="text-uppercase from-top mb-0">{this.props.photographer.name}</h5>
                        </figcaption>
                    </figure>
                    <h4 className="text-uppercase mb-0">{this.props.photographer.name}</h4>
                </div>
            </div>
        )
    }
}

PhotographerCardComponent.propTypes = {
    photographer: PropTypes.object.isRequired,
};

export default PhotographerCardComponent;