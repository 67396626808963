import React from "react";
import Sticky from 'react-stickynode';
import NavigationComponent from "../components/NavigationComponent";
import ExpositionsComponent from "../components/ExpositionsComponent";
import PhotographersComponent from "../components/PhotographersComponent";
import FooterComponent from "../components/FooterComponent";
import ClubFeaturedComponent from "../components/ClubFeaturedComponent";
import Slider from 'react-animated-slider';
import ReactGA from 'react-ga';
import 'react-animated-slider/build/horizontal.css';
import IntlMessages from "../util/IntlMessages";
import { Parallax } from 'react-parallax';
import {Modal} from "reactstrap";
import SignupForm from "../forms/SignupForm";
import {NavLink} from "react-router-dom";

class HomePage extends React.Component {

    state = {
        modalSignupOpen: false,
    };

    componentDidMount = () => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.scrollTo(0, 0);
    };

    toggleModalSignup = () => {
        this.setState({
            modalSignupOpen: !this.state.modalSignupOpen
        });
    };

    renderOld() {
        return (
            <div className="content-wrapper">

                <Sticky>
                    {({
                          style,

                          // the following are also available but unused in this example
                          isSticky,
                          wasSticky,
                          distanceFromTop,
                          distanceFromBottom,
                          calculatedHeight
                      }) => (
                        <header style={style}>
                            <NavigationComponent/>
                        </header>
                    )}
                </Sticky>

                <Slider autoplay={3000}>
                    <div
                        style={{ background: `url('style/images/art/banner_1.jpg') no-repeat center center` }}
                    >
                        <div className="slider">
                            <h1><IntlMessages id="menu.exhibitions"/></h1>
                            <p><IntlMessages id="exhibitions.slogan"/></p>
                        </div>
                    </div>
                    <div
                        style={{ background: `url('style/images/art/slider-bg74.jpg') no-repeat center center` }}
                    >
                        <div className="slider">
                            <h1><IntlMessages id="menu.photographers"/></h1>
                            <p><IntlMessages id="photographers.slogan"/></p>
                        </div>
                    </div>
                </Slider>

                <ExpositionsComponent/>
                <PhotographersComponent/>
                <ClubFeaturedComponent/>
                <FooterComponent/>

            </div>
        )
    }

    render() {
        return (
            <div className="content-wrapper">
                <Sticky enabled={true} innerZ={999999}>
                    <NavigationComponent/>
                </Sticky>


                <div className="wrapper bg-pastel-default">
                    <div className="container inner pt-100 pb-190">
                        <div className="row">
                            <div className="col-md-10 offset-md-1 text-center">
                                <h1 className="heading">
                                    <IntlMessages id="home.welcome"/>
                                </h1>
                                <div className="space10"></div>
                                <h2 className="sub-heading2 mb-0">
                                    <IntlMessages id="home.message"/>
                                </h2>
                                <div className="space140"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <ExpositionsComponent/>

                <Parallax
                    bgImage={'/style/images/art/background-1.jpg'}
                    bgImageAlt="the cat"
                    strength={200}
                >
                    <div className="wrapper image-wrapper bg-image inverse-text" >
                        <div className="container inner pt-120 pb-120 text-center">
                            <div className="row">
                                <div className="col-md-10 offset-md-1">
                                    <h2 className="section-title text-center">
                                        <IntlMessages id="keep-in-touch.title"/>
                                    </h2>
                                    <p className="lead text-center">
                                        <IntlMessages id="keep-in-touch.message1"/>
                                    </p>
                                    <p>
                                        <IntlMessages id="keep-in-touch.message2"/>
                                    </p>
                                    <div className="space15"></div>
                                    <button className="btn btn-white shadow" onClick={this.toggleModalSignup}>
                                        <IntlMessages id="user.signup"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Parallax>

                <Modal
                    isOpen={this.state.modalSignupOpen}
                    toggle={this.toggleModalSignup}
                    backdrop={true}
                    wrapClassName="modal-right"
                    style={{top: 100}}
                >
                    <SignupForm state={this.state} toggle={this.toggleModalSignup}/>
                </Modal>

                <PhotographersComponent/>

                <ClubFeaturedComponent/>


                <div className="wrapper bg-pastel-default">
                    <div className="container inner text-center">
                        <h2 className="section-title text-center">
                            <IntlMessages id="clubs.title"/>
                        </h2>
                        <h3 className="lead larger text-center">
                            <IntlMessages id="clubs.message"/>
                        </h3>
                        <div className="space20"></div>
                        <NavLink to="/clubs/search" className="btn btn-l btn-white">
                            <IntlMessages id="clubs.search"/>
                        </NavLink>
                    </div>
                </div>

                <FooterComponent/>

            </div>
        )
    }

}

export default HomePage;