import React from "react";
import {HostContext} from "../App";
import PhotographerCardComponent from "./PhotographerCardComponent";
import {injectIntl} from "react-intl";
import {NavLink} from "react-router-dom";
import IntlMessages from "../util/IntlMessages";

class PhotographersComponent extends React.Component {

    state = {
        color_wrapper: "",
        title: "",
        photographers: []
    };

    constructor(props) {
        super(props);

        const {messages} = this.props.intl;

        if (typeof(props.color_wrapper) === "undefined") {
            this.state.color_wrapper = "light-wrapper"
        } else {
            this.state.color_wrapper = props.color_wrapper;
        }

        if (typeof(props.title) === "undefined") {
            this.state.title = messages['photographers.title']
        } else {
            this.state.title = props.title;
        }
    }

    componentWillMount() {
        fetch(HostContext.hostName + '/photographers/featured')
            .then(response => response.json())
            .then(json => {
                this.setState({photographers: json}, () => {
                    this.forceUpdate();
                });
            });
    }

    render() {
        return (
            <div className={"wrapper " + this.state.color_wrapper}>
                <div className="container inner">
                    <h2 className="section-title text-center mb-30">{this.state.title}</h2>
                    <div className="space30"></div>
                    <div className="tiles grid">
                        <div className="items row isotope boxed grid-view text-center">

                            {this.state.photographers.map((photographer, index) => (
                                <PhotographerCardComponent key={index} photographer={photographer}/>
                            ))}

                        </div>

                        <div className="row">
                            <div className="col-md-12 text-center">
                                <NavLink to="/photographers" className="btn btn-white shadow">
                                    <IntlMessages id="photographers.discover-others"/>
                                </NavLink>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

PhotographersComponent.propTypes = {
    //exposition: PropTypes.object.isRequired,
};

export default injectIntl(PhotographersComponent);