import React from "react";
import 'moment/locale/fr';

class ClubFeaturedComponent extends React.Component {

    render() {
        return (
            <div></div>
        )
    }
    /*
    render() {
        return (
            <div className="wrapper light-wrapper">
                <div className="container inner">
                    <h2 className="section-title text-center">Clubs Photos</h2>
                    <p className="lead text-center">My traveling diary about the places I have visited recently</p>
                    <div className="space30"></div>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="blog grid grid-view boxed boxed-classic-view">
                                <div className="post">
                                    <div className="box bg-white shadow">
                                        <figure className="main mb-30 overlay overlay1 rounded"><a href="#"> <img
                                            src="style/images/art/b8.jpg" alt=""/></a>
                                            <figcaption>
                                                <h5 className="text-uppercase from-top mb-0">Read More</h5>
                                            </figcaption>
                                        </figure>
                                        <div className="meta mb-10"><span className="category"><a href="#"
                                                                                                  className="hover color">Reflection</a></span>
                                        </div>
                                        <h2 className="post-title"><a href="blog-post.html">Ligula tristique quis risus
                                            eget urna mollis ornare porttitor</a></h2>
                                        <div className="post-content">
                                            <p>Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget
                                                lacinia odio sem nec elit. Cum sociis natoque. Nullam id dolor id nibh.
                                                Sed posuere consectetur est at lobortis. Curabitur blandit tempus
                                                porttitor. Nulla
                                                vitae elit libero, a pharetra augue.</p>
                                        </div>
                                        <hr/>
                                        <div className="meta meta-footer d-flex justify-content-between mb-0"><span
                                            className="date">5 Jul 2018</span><span className="comments"><a
                                            href="#">3</a></span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <aside className="col-md-4 sidebar">
                            <div className="sidebox widget">
                                <h3 className="widget-title">Popular Posts</h3>
                                <ul className="image-list">
                                    <li>
                                        <figure className="rounded"><a href="blog-post.html"><img
                                            src="style/images/art/a1.jpg" alt=""/></a></figure>
                                        <div className="post-content">
                                            <h6 className="post-title"><a href="blog-post.html">Magna Mollis Ultricies
                                                Mauris</a></h6>
                                            <div className="meta"><span className="date">12 Nov 2014</span><span
                                                className="comments"><a href="#">4</a></span></div>
                                        </div>
                                    </li>
                                    <li>
                                        <figure className="rounded"><a href="blog-post.html"><img
                                            src="style/images/art/a2.jpg" alt=""/></a></figure>
                                        <div className="post-content">
                                            <h6 className="post-title"><a href="blog-post.html">Ornare Nullam Risus
                                                Cursus</a></h6>
                                            <div className="meta"><span className="date">12 Nov 2014</span><span
                                                className="comments"><a href="#">4</a></span></div>
                                        </div>
                                    </li>
                                    <li>
                                        <figure className="rounded"><a href="blog-post.html"><img
                                            src="style/images/art/a3.jpg" alt=""/></a></figure>
                                        <div className="post-content">
                                            <h6 className="post-title"><a href="blog-post.html">Euismod Nullam Fusce</a>
                                            </h6>
                                            <div className="meta"><span className="date">12 Nov 2014</span><span
                                                className="comments"><a href="#">4</a></span></div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="sidebox widget">
                                <h3 className="widget-title">Tags</h3>
                                <ul className="list-unstyled tag-list">
                                    <li><a href="#" className="btn btn-white shadow">Still Life</a></li>
                                    <li><a href="#" className="btn btn-white shadow">Urban</a></li>
                                    <li><a href="#" className="btn btn-white shadow">Nature</a></li>
                                    <li><a href="#" className="btn btn-white shadow">Landscape</a></li>
                                    <li><a href="#" className="btn btn-white shadow">Macro</a></li>
                                    <li><a href="#" className="btn btn-white shadow">Fun</a></li>
                                    <li><a href="#" className="btn btn-white shadow">Workshop</a></li>
                                    <li><a href="#" className="btn btn-white shadow">Photography</a></li>
                                </ul>
                            </div>
                            <div className="sidebox widget">
                                <h3 className="widget-title">Galleries</h3>
                                <div className="tiles tiles-s">
                                    <div id="instafeed-widget" className="items row"></div>
                                </div>
                            </div>
                            <div className="sidebox widget">
                                <h3 className="widget-title">Search</h3>
                                <form className="search-form">
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Search something" />
                                    </div>
                                </form>
                            </div>
                        </aside>
                    </div>
                </div>
            </div>
        )
    }
     */
}

ClubFeaturedComponent.propTypes = {
    //club: PropTypes.object.isRequired,
};

export default ClubFeaturedComponent;