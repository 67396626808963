import React from "react";
import {Link} from "react-router-dom";
import {HostContext, UserContext} from "../App";
import NavigationComponent from "../components/NavigationComponent";
import FooterComponent from "../components/FooterComponent";
import Map from 'pigeon-maps';
import Marker from 'pigeon-marker';
import ExpositionCardComponent from "../components/ExpositionCardComponent";
import ReactGA from "react-ga";
import {EmailShareButton, FacebookShareButton, TwitterShareButton, WhatsappShareButton} from "react-share";
import ReactTooltip from 'react-tooltip'
import IntlMessages from "../util/IntlMessages";
import {injectIntl} from "react-intl";
import {sprintf} from "sprintf-js";
import Moment from "moment";

class ExpositionDetailPage extends React.Component {

    state = {
        dataLoaded: false,
        others: [],
        exposition: {
            name: "",
            message: "",
            media: {
                url: ""
            },
            place: {
                name: "",
                address: "",
                postal_code: "",
                city: "",
            }
        }
    };

    componentDidMount = () => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    };

    componentWillMount = () => {
        this.loadData(this.props.match.params.id);
    };

    componentWillReceiveProps(nextProps) {
        console.log("componentWillReceiveProps");
        console.log(nextProps.match.params.id);
        console.log(nextProps);
        this.loadData(nextProps.match.params.id);
    }

    loadData = (id) => {
        fetch(HostContext.hostName + '/expositions/' + id)
            .then(response => response.json())
            .then(json => {
                this.setState({
                    exposition: json,
                    dataLoaded: true
                });
            });

        fetch(HostContext.hostName + '/expositions/others/' + id)
            .then(response => response.json())
            .then(json => {
                this.setState({
                    others: json,
                });
            });
    };

    renderMap = () => {
        if (this.state.dataLoaded) {
            const latitude = this.state.exposition.place.lat;
            const longitude = this.state.exposition.place.long;
            return (
                <Map center={[latitude, longitude]} zoom={16} width={600} height={400}>
                    <Marker anchor={[latitude, longitude]} payload={1} onClick={({ event, anchor, payload }) => {}} />
                </Map>
            )
        } else {
            return (
                <div></div>
            )
        }
    };

    renderOthers = () => {
        if (this.state.others.length < 1) {
            return (<div></div>)
        }

        return (
            <div data-aos="fade-left" className="wrapper light-wrapper">
                <div className="container inner">
                    <h2 className="section-title section-title-upper larger text-center"><IntlMessages id="exhibitions.other"/></h2>
                    <p className="lead text-center"><IntlMessages id="exhibitions.other-message"/></p>
                    <div className="space30"></div>
                    <div className="blog grid grid-view boxed">
                        <div className="row">

                            {this.state.others.map((exposition, index) => (
                                <ExpositionCardComponent key={index} exposition={exposition}/>
                            ))}


                        </div>
                    </div>
                </div>
            </div>
        )
    };

    render() {
        const {messages} = this.props.intl;
        Moment.locale(UserContext.locale.substring(0, 2));

        return (
            <div className="content-wrapper">
                <NavigationComponent/>

                <div className="wrapper gray-wrapper">
                    <div className="container inner">
                        <div className="row d-flex align-items-center">
                            <div className="col-lg-7 pr-35 pr-sm-15">
                                <figure className="rounded"><img src={this.state.exposition.media.url} alt="" /></figure>
                            </div>
                            <div className="space30 d-block d-lg-none d-xl-none"></div>
                            <div className="col-lg-5">
                                <h2 className="section-title section-title-upper larger">
                                    {this.state.exposition.name}
                                </h2>
                                <div dangerouslySetInnerHTML={{__html: this.state.exposition.message}} />

                                <FacebookShareButton
                                    url={window.location.href}
                                    quote={sprintf(messages["social.share-facebook-quote"], this.state.exposition.name)}>
                                    <div data-tip data-for='shareFacebook' className="btn btn-circle mr-5"><i className="fa fa-facebook"></i></div>
                                    <ReactTooltip id='shareFacebook' type='light' effect='solid' place='bottom'>
                                        <span><IntlMessages id="social.share-facebook"/></span>
                                    </ReactTooltip>
                                </FacebookShareButton>

                                <TwitterShareButton
                                    url={window.location.href}
                                    title={sprintf(messages["social.share-twitter-title"], this.state.exposition.name)}>
                                    <div data-tip data-for='shareTwitter' className="btn btn-circle mr-5"><i className="fa fa-twitter"></i></div>
                                    <ReactTooltip id='shareTwitter' type='light' effect='solid' place='bottom'>
                                        <span><IntlMessages id="social.share-twitter"/></span>
                                    </ReactTooltip>
                                </TwitterShareButton>

                                <WhatsappShareButton
                                    url={window.location.href}
                                    title={sprintf(messages["social.share-twitter-title"], this.state.exposition.name)}>
                                    <div data-tip data-for='shareWhatsapp' className="btn btn-circle mr-5"><i className="fa fa-whatsapp"></i></div>
                                    <ReactTooltip id='shareWhatsapp' type='light' effect='solid' place='bottom'>
                                        <span><IntlMessages id="social.share-whatsapp"/></span>
                                    </ReactTooltip>
                                </WhatsappShareButton>

                                <EmailShareButton
                                    url={sprintf(messages["social.share-email-url"], window.location.href)}
                                    separator={"\n\n"}
                                    subject={messages["social.share-email-subject"]}
                                    body={sprintf(messages["social.share-email-body"],
                                        this.state.exposition.name,
                                        this.state.exposition.place.name,
                                        this.state.exposition.place.address,
                                        this.state.exposition.place.postal_code,
                                        this.state.exposition.place.city,
                                        Moment(this.state.exposition.start).format(messages["general.date-format"]),
                                        Moment(this.state.exposition.finish).format(messages["general.date-format"]))}>
                                    <div data-tip data-for='shareEmail' className="btn btn-circle"><i className="fa fa-send"></i></div>
                                    <ReactTooltip id='shareEmail' type='light' effect='solid' place='bottom'>
                                        <span><IntlMessages id="social.share-email"/></span>
                                    </ReactTooltip>
                                </EmailShareButton>
                            </div>
                        </div>
                    </div>
                </div>

                <div data-aos="fade-right" className="wrapper white-wrapper">
                    <div className="container inner">
                        <div className="row d-flex align-items-center">
                            <div className="col-lg-5">
                                <h2 className="section-title section-title-upper larger">
                                    {this.state.exposition.place.name}
                                </h2>
                                <p>{this.state.exposition.place.address}</p>
                                <p>{this.state.exposition.place.postal_code} {this.state.exposition.place.city}</p>
                            </div>
                            <div className="space30 d-block d-lg-none d-xl-none"></div>
                            <div className="col-lg-7 pr-35 pr-sm-15">
                                {this.renderMap()}
                            </div>
                        </div>
                    </div>
                </div>

                {this.renderOthers()}

                <div className="wrapper gray-wrapper">
                    <div className="container inner text-center">
                        <h2 className="sub-heading text-center"><IntlMessages id="exhibitions.search-around"/></h2>
                        <div className="space10"></div>
                        <Link to="/expositions/search" className="btn btn-white shadow"><IntlMessages id="general.search"/></Link>
                    </div>
                </div>

                <FooterComponent/>

            </div>

        )
    }
}

export default injectIntl(ExpositionDetailPage);