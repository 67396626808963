import React from "react";
import NavigationComponent from "../components/NavigationComponent";
import FooterComponent from "../components/FooterComponent";
import {loadReCaptcha, ReCaptcha} from 'react-recaptcha-v3';
import * as Yup from "yup";
import {Field, Form, Formik, ErrorMessage} from "formik";
import {HostContext} from "../App";
import ReactGA from "react-ga";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import IntlMessages from "../util/IntlMessages";
import {injectIntl} from "react-intl";

class ContactPage extends React.Component {

    state = {
        recaptcha_token: ""
    };

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        loadReCaptcha("6LepWMsUAAAAAOLMFWtvkP8cZXFf__QL_uK9_lWg");
    }

    verifyCallback = (recaptchaToken) => {
        // Here you will get the final recaptchaToken!!!
        console.log(recaptchaToken, "<= your recaptcha token")
        this.setState({
            recaptcha_token: recaptchaToken
        })
    };

    handleSubmit = async (values, { setErrors }) => {
        values["recaptcha_token"] = this.state.recaptcha_token;
        console.log(values);

        fetch(HostContext.hostName + '/contacts', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(values)
        }).then(response => {
            if (response.ok) {
                return response.json()
            }
            switch (response.status) {
                case 401:
                    throw Error(response.statusText);
                default:
                    throw Error(response.statusText);
            }
        }).then(json => {
            //this.props.toggle(true);
            console.log(json.returnCode);
            if (json.returnCode) {
                toast.success("Message bien envoyé !", {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            } else {
                toast.error("Problème à l'envoi du message !", {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            }
            //this.dataListRender();
            //this.toggleModal();
        }).catch(function (error) {
            console.log(error);
            //that.setState({'requestError': error.message});
        });

    };

    render() {

        const ContactSchema = Yup.object().shape({
            firstname: Yup.string().required("Ce champ est requis."),
            lastname: Yup.string().required("Ce champ est requis."),
            email: Yup.string().email("Une adresse valide est requise").required("Ce champs est requis"),
            subject: Yup.string().required("Ce champ est requis."),
            message: Yup.string().required("Ce champ est requis."),
        });

        const {messages} = this.props.intl;

        return (
            <div className="content-wrapper">
                <NavigationComponent/>

                <div className="wrapper light-wrapper">
                    <div className="container inner pt-70">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                                <h2 className="section-title text-center"><IntlMessages id="contact.title"/></h2>
                                <p className="text-center"><IntlMessages id="contact.message"/></p>
                                <div className="space20"></div>
                                <div className="row text-center">
                                    <div className="col-md-12"><span
                                        className="icon icon-color color-default fs-48 mb-10"><i
                                        className="si-mail_mail-2"></i></span>
                                    </div>
                                </div>
                                <div className="space30"></div>
                                <div className="form-container">

                                    <Formik
                                        initialValues={{
                                            firstname: '',
                                            lastname: '',
                                            email: '',
                                            subject: '',
                                            message: '',
                                        }}
                                        validationSchema={ContactSchema}
                                        onSubmit={this.handleSubmit}
                                        isInitialValid={false}
                                    >
                                        {({ errors, touched, isValid }) => (

                                            <Form action="contact/vanilla-form.php" method="post"
                                                  className="vanilla vanilla-form" noValidate>
                                                <div className="row text-center">
                                                    <div className="col-md-6 pr-10">
                                                        <div className="form-group">
                                                            <Field type="text" name="firstname" className="form-control" placeholder={messages['contact.firstname']} />
                                                            <ErrorMessage name="firstname" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 pl-10">
                                                        <div className="form-group">
                                                            <Field type="text" name="lastname" className="form-control" placeholder={messages['contact.lastname']} />
                                                            <ErrorMessage name="lastname" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 pr-10">
                                                        <div className="form-group">
                                                            <Field type="email" name="email" className="form-control" placeholder={messages['contact.email']} />
                                                            <ErrorMessage name="email" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 pl-10">
                                                        <div className="form-group">
                                                            <Field type="text" name="subject" className="form-control" placeholder={messages['contact.subject']} />
                                                            <ErrorMessage name="subject" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <Field as="textarea" name="message" className="form-control" rows="3" placeholder={messages['contact.text']} />
                                                        <ErrorMessage name="message" />
                                                        <div className="space20"></div>

                                                        <ReCaptcha
                                                            sitekey="6LepWMsUAAAAAOLMFWtvkP8cZXFf__QL_uK9_lWg"
                                                            action='action_name'
                                                            verifyCallback={this.verifyCallback}
                                                        />

                                                        <button type="submit" className="btn" disabled={!isValid}><IntlMessages id="contact.submit"/></button>

                                                        <footer className="notification-box"></footer>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <FooterComponent/>

                <ToastContainer
                    position="top-right"
                    autoClose={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                />
            </div>

        )
    }
}

export default injectIntl(ContactPage);