import React from "react";
import ReactGA from "react-ga";
import Sticky from "react-stickynode";
import NavigationComponent from "../components/NavigationComponent";
import FooterComponent from "../components/FooterComponent";
import {HostContext} from "../App";

class RegisterPage extends React.Component {

    componentDidMount = () => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        console.log(this.props.match.params.token);

        let url = HostContext.hostName + '/users/register-check';

        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({token: this.props.match.params.token})
        }).then(function(response) {
            const statusCode = response.status;
            const data = response.json();
            return Promise.all([statusCode, data]);
        }).then(function([statusCode, data]) {
            switch (statusCode) {
                case 200:
                    console.log("200 - ok");
                    break;
                case 422:
                    console.log("422 - bad token");
                    break;
                default:
                    console.log("other - error");
                    break;
            }
        }).catch(function(error) {
            console.log(error.message);
        });
    };

    render() {
        return (
            <div className="content-wrapper">
                <Sticky enabled={true} innerZ={999999}>
                    <NavigationComponent/>
                </Sticky>

                <div className="wrapper gray-wrapper">
                    <div className="container inner">
                        <h2 className="section-title text-center mb-50">
                           Register Page
                        </h2>
                    </div>
                </div>

                <FooterComponent/>

            </div>
        )
    }
}

export default RegisterPage;

//                             <IntlMessages id="general.page-notfound"/>