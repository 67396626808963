import React from "react";
import {HostContext} from "../App";
import {injectIntl} from "react-intl";
import PropTypes from "prop-types";
import ExpositionCardComponent from "./ExpositionCardComponent";
import {NavLink} from "react-router-dom";
import IntlMessages from "../util/IntlMessages";

class ExpositionsComponent extends React.Component {

    state = {
        color_wrapper: "",
        title: "",
        expositions: []
    }

    constructor(props) {
        super(props);

        const {messages} = this.props.intl;

        if (typeof(props.color_wrapper) === "undefined") {
            this.state.color_wrapper = "bleed-wrapper"
        } else {
            this.state.color_wrapper = props.color_wrapper;
        }

        if (typeof(props.title) === "undefined") {
            this.state.title = messages['exhibitions.title']
        } else {
            this.state.title = props.title;
        }
    }

    componentWillMount() {
        if (typeof(this.props.place_id) !== "undefined") {
            fetch(HostContext.hostName + '/places/' + this.props.place_id + '/expositions')
                .then(response => response.json())
                .then(json => {
                    this.setState({expositions: json}, () => {
                        this.forceUpdate();
                    });
                });
        } else {
            fetch(HostContext.hostName + '/expositions')
                .then(response => response.json())
                .then(json => {
                    this.setState({expositions: json}, () => {
                        this.forceUpdate();
                    });
                });
        }
    }

render() {
        return (
            <div className={"wrapper " + this.state.color_wrapper + " nmt-220"}>
                <div className="container inner pt-0">
                    <h2 className="section-title text-center mb-30">{this.state.title}</h2>
                    <div className="space20"></div>
                    <div className="blog grid grid-view boxed">
                        <div className="row">
                            {this.state.expositions.map((exposition, index) => (
                                <ExpositionCardComponent key={index} exposition={exposition}/>
                            ))}
                        </div>

                        <div className="row">
                            <div className="col-md-12 text-center">
                                <NavLink to="/expositions/current" className="btn btn-white shadow">
                                    <IntlMessages id="exhibitions.discover-others"/>
                                </NavLink>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

ExpositionsComponent.propTypes = {
    color_wrapper: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    place_id: PropTypes.string,
};

export default injectIntl(ExpositionsComponent);