import React from "react";
import ReactGA from "react-ga";
import {HostContext} from "../App";
import NavigationComponent from "../components/NavigationComponent";
import Map from "pigeon-maps";
import Marker from "pigeon-marker";
import ExpositionsComponent from "../components/ExpositionsComponent";
import FooterComponent from "../components/FooterComponent";

class PlaceDetailPage extends React.Component {

    state = {
        place: {
            name: "",
            address: "",
            postal_code: "",
            city: "",
            website: ""
        },
        dataLoaded: false
    };

    componentDidMount = () => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    };

    componentWillMount = () => {
        fetch(HostContext.hostName + '/places/' + this.props.match.params.id)
            .then(response => response.json())
            .then(json => {
                this.setState({
                    place: json,
                    dataLoaded: true
                });
            });
    };

    renderMap = () => {
        if (this.state.dataLoaded) {
            const latitude = this.state.place.lat;
            const longitude = this.state.place.long;
            return (
                <Map center={[latitude, longitude]} zoom={16} width={600} height={400}>
                    <Marker anchor={[latitude, longitude]} payload={1} onClick={({ event, anchor, payload }) => {}} />
                </Map>
            )
        } else {
            return (
                <div></div>
            )
        }
    };

    renderExpositions = () => {
        if (this.state.dataLoaded) {
            return (
                <ExpositionsComponent
                    place_id={this.state.place.id}
                    subtitle=""
                />
            )
        } else {
            return
        }
    };

    renderWebsite = () => {
        if (this.state.place.website.length > 0) {
            return (
                <ul className="social social-bg social-color social-bg-white">
                    <li>
                        <a href={this.state.place.website} target="_blank" rel="noopener noreferrer">
                            <i className="fa fa-external-link"></i>
                        </a>
                    </li>
                </ul>
            )
        }
    };

    render() {
        return (
            <div className="content-wrapper">
                <NavigationComponent/>

                <div className="wrapper light-wrapper">
                    <div className="container inner">
                        <div className="row d-flex align-items-center">
                            <div className="col-lg-5">
                                <h2 className="section-title section-title-upper larger">
                                    {this.state.place.name}
                                </h2>
                                <p>{this.state.place.address}</p>
                                <p>{this.state.place.postal_code} {this.state.place.city}</p>
                                {this.renderWebsite()}
                            </div>
                            <div className="space30 d-block d-lg-none d-xl-none"></div>
                            <div className="col-lg-7 pr-35 pr-sm-15">
                                {this.renderMap()}
                            </div>
                        </div>
                    </div>
                </div>

                {this.renderExpositions()}

                <FooterComponent/>

            </div>
        )
    }
}

export default PlaceDetailPage;