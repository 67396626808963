import React from "react";
import ReactGA from "react-ga";
import IntlMessages from "../util/IntlMessages";
import {ReactSVG} from "react-svg";
import Sticky from "react-stickynode";
import NavigationComponent from "../components/NavigationComponent";
import FooterComponent from "../components/FooterComponent";

class NotFoundPage extends React.Component {

    componentDidMount = () => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    };

    render() {
        return (
            <div className="content-wrapper">
                <Sticky enabled={true} innerZ={999999}>
                    <NavigationComponent/>
                </Sticky>

                <div className="wrapper gray-wrapper">
                    <div className="container inner">
                        <h2 className="section-title text-center mb-50">
                            <IntlMessages id="general.page-notfound"/>
                        </h2>
                        <ReactSVG src="/style/images/art/page-notfound.svg" />
                    </div>
                </div>

                <FooterComponent/>

            </div>
        )
    }
}

export default NotFoundPage;