import React from "react";
import ReactGA from "react-ga";
import {NavLink} from "react-router-dom";
import IntlMessages from "../util/IntlMessages";
import {ReactSVG} from "react-svg";
import PropTypes from "prop-types";
import {sprintf} from "sprintf-js";
import Moment from 'moment';
import 'moment/locale/fr';
import {injectIntl} from "react-intl";
import {UserContext} from "../App";

class MaintenancePage extends React.Component {

    componentDidMount = () => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    };

    renderReopeningTime = () => {
        const {messages} = this.props.intl;

        if (this.props.reopeningTime === undefined) {
            return
        }

        Moment.locale(UserContext.locale.substring(0, 2));
        const reopeningDate = Moment(this.props.reopeningTime);

        return(
            <h3 className="text-center mb-50">
                {sprintf(messages["general.reopening"], reopeningDate.format('dddd DD MMMM YYYY'), reopeningDate.format('hh:mm'))}
            </h3>
        )
    };

    render() {
        return (
            <div className="content-wrapper">
                <nav className="navbar center navbar-expand-lg mt-20 mb-20">
                    <div className="container flex-lg-column">
                        <div className="navbar-brand">
                            <NavLink to={"/"}>
                                <img src="#" srcSet="/style/images/logo.png 1x, /style/images/logo@2x.png 2x" alt=""/>
                            </NavLink>
                        </div>
                    </div>
                </nav>

                <div className="wrapper gray-wrapper full-height">
                    <div className="container inner">
                        <h2 className="section-title text-center mb-50">
                            <IntlMessages id="general.under-maintenance"/>
                        </h2>
                        {this.renderReopeningTime()}
                        <ReactSVG src="/style/images/art/under-maintenance.svg" />
                    </div>
                </div>
            </div>
        )
    }

}

MaintenancePage.propTypes = {
    reopeningTime: PropTypes.string,
};


export default injectIntl(MaintenancePage);