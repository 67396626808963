//import appLocaleData from 'react-intl/locale-data/fr';
import frMessages from "../locales/fr-FR.json";

const FrLang = {
    messages: {
        ...frMessages
    },
    locale: 'fr-FR',
//    data: appLocaleData
};
export default FrLang;