import React from "react";
import NavigationComponent from "../components/NavigationComponent";
//import French from "@svg-maps/france.regions";
//import France from "../maps/france.departements";
import World from "../maps/world";
import { SVGMap } from "react-svg-map";
import "react-svg-map/lib/index.css";
import {Field, Form, Formik} from "formik";
import * as Yup from 'yup';
import FooterComponent from "../components/FooterComponent";
import ReactGA from "react-ga";
import IntlMessages from "../util/IntlMessages";
import {injectIntl} from "react-intl";
import Sticky from "react-stickynode";

class ExpositionSearchPage extends React.Component {

    state = {
        country: "Choisir un pays"
    };

    constructor(props) {
        super(props);

        const {messages} = props.intl;
        this.state.country = messages['exhibitions.choose-country'];
    }

    componentDidMount = () => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        window.scrollTo(0, 0);
    };

    handleMouseOver = (event) => {
        this.setState({country: event.target.attributes.name.value});
    };

    handleMouseOut = (event) => {
        const {messages} = this.props.intl;
        this.setState({country: messages['exhibitions.choose-country']});
    };

    handleClick = (event) => {
        console.log(event.target.attributes.id.value);
        console.log(event.target.attributes.id.value.length);
        if (event.target.attributes.id.value.length > 4) {
            const dept = event.target.attributes.id.value.substr(3);
            console.log(dept);
            this.props.history.push("/expositions/result"
                + "?dept=" + dept
            );

        }
    };

    handleSubmit = async (values, { setErrors }) => {
        this.props.history.push("/expositions/result"
            + "?text=" + values['text']
        );
    };

    render() {

        const SearchSchema = Yup.object().shape({
            text: Yup.string()
                .required("Ce champ est requis."),
        });

        return (
            <div className="content-wrapper">
                <Sticky enabled={true} innerZ={999999}>
                    <NavigationComponent/>
                </Sticky>

                <div className="wrapper bg-pastel-default">
                    <div className="container inner">
                        <h2 className="section-title text-center"><IntlMessages id="exhibitions.search-city"/></h2>
                        <div className="space20"></div>

                        <div className="form-container">

                            <Formik
                                initialValues={{
                                    text: '',
                                }}
                                validationSchema={SearchSchema}
                                onSubmit={this.handleSubmit}
                                isInitialValid={false}
                            >
                                {({ errors, touched, isValid }) => (
                                    <Form className="vanilla vanilla-form" noValidate>
                                        <div className="row text-center">
                                            <div className="col-12">
                                                <Field type="text" name="text" className="form-control" />
                                                {errors.text && touched.text ? (<div className="form-error">{errors.text}</div>) : null}
                                                <div className="space20"></div>
                                                <button type="submit" className="btn" disabled={!isValid}><IntlMessages id="general.search"/></button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>

                <div className="wrapper white-wrapper">
                    <div className="container inner">
                        <h2 className="section-title text-center"><IntlMessages id="exhibitions.search-world"/></h2>
                        <p className="lead text-center">{this.state.country}</p>
                        <div className="space30"></div>
                        <div className="text-center">
                            <SVGMap map={World} className="map-expositions"
                                    onLocationMouseOver={this.handleMouseOver}
                                    onLocationMouseOut={this.handleMouseOut}
                                    onLocationClick={this.handleClick}
                            />
                        </div>
                    </div>
                </div>

                <FooterComponent/>

            </div>
        )
    }
}

export default injectIntl(ExpositionSearchPage);