import React from "react";
import NavigationComponent from "../components/NavigationComponent";
import FooterComponent from "../components/FooterComponent";
import ReactGA from "react-ga";
import {UserContext} from "../App";

class PrivacyPolicyPage extends React.Component {

    componentDidMount = () => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    };

    renderFrench = () => {
        return (
            <div className="content-wrapper">
                <NavigationComponent/>

                <div className="wrapper light-wrapper">
                    <div className="container">
                        <div className="row mt-20">
                            <div className="col-sm-12">
                                <h1>POLITIQUE DE CONFIDENTIALITÉ</h1>
                                <p>
                                    Les traitements de données personnelles effectués sur zimbel.org sont conformes au règlement général sur la protection des données (RGPD) et à la loi Informatique et Libertés.
                                </p>
                            </div>
                        </div>

                        <div className="row mt-20">
                            <div className="col-sm-12">
                                <ol>
                                    <li><a href="#paragraph-1">Politique de protection des données</a></li>
                                    <li><a href="#paragraph-2">Objet du traitement de données</a></li>
                                    <li><a href="#paragraph-3">Données traitées</a></li>
                                    <li><a href="#paragraph-4">Personnes concernées</a></li>
                                    <li><a href="#paragraph-5">Destinataires des données</a></li>
                                    <li><a href="#paragraph-6">Durée de conservation des données</a></li>
                                    <li><a href="#paragraph-7">Sécurité</a></li>
                                    <li><a href="#paragraph-8">Vos droits sur les données vous concernant</a></li>
                                    <li><a href="#paragraph-9">A propos des cookies</a></li>
                                    <ol>
                                        <li><a href="#paragraph-9-1">Cookies internes nécessaires au site pour fonctionner</a></li>
                                        <li><a href="#paragraph-9-2">Cookies tiers destinés à améliorer l’interactivité du site</a></li>
                                    </ol>
                                </ol>
                            </div>
                        </div>

                        <div className="row mt-20">
                            <div className="col-sm-12">
                                <h2 id="paragraph-1">1. Politique de protection des données</h2>
                                <p>
                                    Ce service en ligne limite la collecte des données personnelles au strict nécessaire (minimisation des données) et s’accompagne d’une information sur :
                                </p>
                                <ul>
                                    <li>le responsable du traitement et les objectifs du recueil de ces données (finalités),</li>
                                    <li>la base juridique du traitement de données,</li>
                                    <li>le caractère obligatoire ou facultatif du recueil des données pour la gestion de votre demande et le rappel des catégories de données traitées,</li>
                                    <li>la source des données (lorsque d’autres données que celles fournies via le service en ligne sont utilisées pour le traitement de votre demande),</li>
                                    <li>les catégories de personnes concernées,</li>
                                    <li>les destinataires des données (uniquement zimbel.org en principe, sauf précision lorsqu'une transmission à un tiers est nécessaire)</li>
                                    <li>la durée de conservation des données,</li>
                                    <li>les mesures de sécurité (description générale),</li>
                                    <li>l’existence éventuelle de transferts de données hors de l’Union européenne ou de prises de décision automatisées,</li>
                                    <li>vos droits Informatique et Libertés et la façon de les exercer auprès de la CNIL.</li>
                                </ul>
                                <p>
                                    Les données personnelles recueillies dans le cadre des services proposés sur cnil.fr sont traitées selon des protocoles sécurisés et permettent à la CNIL de gérer les demandes reçues dans ses applications informatiques.
                                </p>
                            </div>
                        </div>


                        <div className="row mt-20">
                            <div className="col-sm-12">
                                <h2 id="paragraph-2">2. Objet du traitement de données</h2>
                                <p>
                                    <b>Finalités</b><br/>
                                    Le traitement a pour objet la gestion des sites web administrés par la CNIL.<br/>
                                    Il permet :
                                </p>
                                <ul>
                                    <li>la préparation et la publication de contenus ;</li>
                                    <li>la mise en ligne des formulaires de contact (webforms) et des appels à contributions (consultations) ;</li>
                                    <li>l'administration technique en lien avec les prestataires (tierce maintenance applicative, hébergement, registraire de noms de domaine, autorité de certification) ;</li>
                                    <li>la gestion de la sécurité des sites (équipements et journaux de sécurité) ;</li>
                                    <li>la production de statistiques d’audience et d’utilisation des services en ligne proposés par la CNIL.</li>
                                </ul>

                                <p>
                                    <b>Base légale</b><br/>
                                    Article 6 (1) e du règlement européen 2016/679 (règlement général sur la protection des données - RGPD) – exercice de l’autorité publique dont est investie la CNIL en vertu du RGPD et de la loi Informatique et Libertés modifiée)
                                </p>
                            </div>
                        </div>

                        <div className="row mt-20">
                            <div className="col-sm-12">
                                <h2 id="paragraph-3">3. Données traitées</h2>
                                <p>
                                    <b>Catégories de données traitées</b>
                                </p>
                                <ul>
                                    <li>Identité, fonctions, coordonnées ;</li>
                                    <li>Données relatives à la navigation sur les sites web  (horodatage, adresse IP des usagers, données techniques relatives à l’équipement et au navigateur utilisés par les usagers, cookies) et sur les plateformes numériques via des boutons de partage et des médias (cookies et autres traceurs) ;</li>
                                    <li>Données relatives à la gestion des contacts usagers (horodatage et objet de la demande, suivi, suites apportées, statistiques) ;</li>
                                    <li>Données relatives à la gestion des publications (objet, livrable, suivi, statistiques) ;</li>
                                    <li>Données relatives à la gestion des prestations techniques (horodatage et objet des demandes, suivi, suites données, statistiques) ;</li>
                                    <li>Statistiques d’audience des sites web et d’utilisation des services en ligne proposés par la CNIL.</li>
                                </ul>

                                <p>
                                    <b>Source des données</b>
                                </p>
                                <ul>
                                    <li>Personnels de la CNIL en charge de la publication des contenus et de l’administration technique des sites web ;</li>
                                    <li>Contributeurs aux publications ;</li>
                                    <li>Usagers des sites web administrés par la CNIL ;</li>
                                    <li>Personnels des prestataires concernés.</li>
                                </ul>

                                <p>
                                    <b>Caractère obligatoire du recueil des données et conséquences en cas de non-fourniture des données.</b><br/>
                                    Les données collectées (notamment les cookies) à l’occasion de la navigation pour la mesure de l’audience des sites web et de l’utilisation des services en ligne présentent un caractère facultatif.<br/>
                                    Les données collectées pour la réalisation des autres finalités sont recueillies de façon obligatoire, sauf – dans le cas d’une collecte directe - mention contraire dans le formulaire concerné ou sur la plateforme numérique concernée.
                                </p>
                                <p>
                                    <b>Prise de décision automatisée</b><br/>
                                    Le traitement ne prévoit pas de prise de décision automatisée.
                                </p>
                            </div>
                        </div>

                        <div className="row mt-20">
                            <div className="col-sm-12">
                                <h2 id="paragraph-4">4. Personnes concernées</h2>
                                <p>
                                    Le traitement de données concerne :
                                </p>
                                <ul>
                                    <li>les personnels de la CNIL en charge de la publication des contenus et de l’administration technique des sites web ;</li>
                                    <li>les membres et personnels de la CNIL, ainsi que les tiers, identifiés dans les publications ;</li>
                                    <li>les usagers des sites web administrés par la CNIL ;</li>
                                    <li>les personnels des prestataires concernés.</li>
                                </ul>
                            </div>
                        </div>


                        <div className="row mt-20">
                            <div className="col-sm-12">
                                <h2 id="paragraph-5">5. Destinataires des données</h2>
                                <p>
                                    <b>Catégories de destinataires</b><br/>
                                    En fonction de leurs besoins respectifs, sont destinataires de tout ou partie des données :
                                </p>
                                <ul>
                                    <li>les personnels de la CNIL en charge de la publication des contenus et de l’administration technique des sites web, et leur hiérarchie ;</li>
                                    <li>les personnels de la CNIL chargés du traitement des demandes reçues via les formulaires de contact ;</li>
                                    <li>les personnels des prestataires concernés ;</li>
                                    <li>les usagers des sites web administrés par la CNIL ;</li>
                                    <li>les personnels responsables de la supervision de la sécurité des systèmes d’information de la CNIL.</li>
                                </ul>

                                <p>
                                    <b>Transferts des données hors UE</b><br/>
                                    Les publications sont susceptibles d’être accessibles, du fait de leur présence sur Internet, hors de l’Union européenne.
                                </p>
                            </div>
                        </div>

                        <div className="row mt-20">
                            <div className="col-sm-12">
                                <h2 id="paragraph-6">6. Durée de conservation des données</h2>
                                <ul>
                                    <li>Les données de préparation des publications (commande, suivi, contenu éditorial) sont conservées cinq ans à compter de la mise en ligne.</li>
                                    <li>Les données relatives aux échanges avec les prestataires sont conservées cinq ans au terme du marché concerné.</li>
                                    <li>Les données collectées via les webforms ou les consultations sont conservées sur une durée maximale de cinq ans à compter de leur réception.</li>
                                    <li>Les données des journaux (logs) sont conservées six mois.</li>
                                    <li>Les données nécessaires à la production de statistiques d’audience et d’utilisation des services en ligne (outil Piwik) sont conservées dans un format ne permettant pas l’identification des personnes par leur adresse IP, et comportent un identifiant (relatif au cookie) conservé pour une durée maximale de treize mois sauf opposition de la personne concernée.</li>
                                </ul>
                            </div>
                        </div>

                        <div className="row mt-20">
                            <div className="col-sm-12">
                                <h2 id="paragraph-7">7. Sécurité</h2>
                                <p>
                                    Les mesures de sécurité sont mises en œuvre conformément à la politique de sécurité des systèmes d’information (PSSI) de la CNIL, issue de la PSSI de l’Etat.
                                </p>
                            </div>
                        </div>

                        <div className="row mt-20">
                            <div className="col-sm-12">
                                <h2 id="paragraph-8">8. Vos droits sur les données vous concernant</h2>
                                <div className="box bg-pastel-default">
                                    <blockquote className="">
                                        <p>
                                            Vous pouvez accéder et obtenir copie des données vous concernant, vous opposer au traitement de ces données, les faire rectifier ou les faire effacer. Vous disposez également d'un droit à la limitation du traitement de vos données.
                                        </p>
                                    </blockquote>
                                </div>

                                <p>
                                    <b>Exercer ses droits</b><br/>
                                    Le délégué à la protection des données (DPO) de la CNIL est votre interlocuteur pour toute demande d'exercice de vos droits sur ce traitement.

                                    Contacter le DPO par voie électronique
                                    > Contacter le délégué à la protection des données (DPO) de la CNIL



                                </p>
                                <p>
                                    <b>Réclamation (plainte) auprès de la CNIL</b><br/>
                                    Si vous estimez, après nous avoir contactés, que vos droits sur vos données ne sont pas respectés, vous pouvez adresser une réclamation (plainte) à la CNIL.
                                </p>
                            </div>
                        </div>

                        <div className="row mt-20">
                            <div className="col-sm-12">
                                <h2 id="paragraph-9">9. A propos des cookies</h2>
                                <p>
                                    Nous utilisons différents cookies sur le site pour améliorer l’interactivité du site et  nos services.
                                </p>
                                <p>
                                    Qu'est-ce qu'un "cookie" ?<br/>
                                    Un "cookie" est une suite d'informations, généralement de petite taille et identifié par un nom, qui peut être transmis à votre navigateur par un site web sur lequel vous vous connectez. Votre navigateur web le conservera pendant une certaine durée, et le renverra au serveur web chaque fois que vous vous y re-connecterez. Les cookies ont de multiples usages : ils peuvent servir à mémoriser votre identifiant client auprès d'un site marchand, le contenu courant de votre panier d'achat, un identifiant permettant de tracer votre navigation pour des finalités statistiques ou publicitaires, etc.
                                </p>
                            </div>
                        </div>

                        <div className="row mt-20">
                            <div className="col-sm-12">
                                <h3 id="paragraph-9-1">9.1. Cookies internes nécessaires au site pour fonctionner</h3>
                                <p>
                                    Ces cookies permettent au site de fonctionner de manière optimale. Vous pouvez vous y opposer et les supprimer en utilisant les paramètres de votre navigateur, cependant votre expérience utilisateur risque d’être dégradée.
                                </p>
                                <table className="table">
                                    <thead className="thead-dark">
                                    <tr>
                                        <th scope="col">Nom du cookie</th>
                                        <th scope="col">Finalité</th>
                                        <th scope="col">Durée de conservation</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <th scope="row">xxxx</th>
                                        <td>yyyy</td>
                                        <td>zzzz</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="row mt-20">
                            <div className="col-sm-12">
                                <h3 id="paragraph-9-2">9.2. Cookies tiers destinés à améliorer l’interactivité du site.</h3>
                                <p>
                                    Le site de la CNIL s’appuie sur certains services proposés par des sites tiers. Il s’agit notamment :
                                </p>
                                <ul>
                                    <li>Des boutons de partage (twitter et facebook)</li>
                                    <li>Des listes de tweets (Twitter)</li>
                                    <li>Des vidéos diffusées sur le site (youtube, dailymotion)</li>
                                    <li>De présentations animées (Prezi)</li>
                                </ul>
                                <p>
                                    Ces fonctionnalités utilisent des cookies tiers directement déposés par ces services. Lors de votre première visite sur cnil.fr, un bandeau vous informe de la présence de ces cookies et vous invite à indiquer votre choix. Ils ne sont déposés que si vous les acceptez ou que vous poursuivez votre navigation sur le site en visitant une seconde page de cnil.fr. Vous pouvez à tout moment vous informer et paramétrer vos cookies pour les accepter ou les refuser en vous rendant sur la page [Gestion des cookies] présente en haut de chaque page du site. Vous pourrez indiquer votre préférence soit globalement pour le site, soit service par service.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>

                <FooterComponent/>
            </div>
        )
    };

    renderEnglish = () => {
        return (
            <div className="content-wrapper">
                <NavigationComponent/>

                <div className="wrapper light-wrapper">
                    <div className="container">
                        <div className="row mt-20">
                            <div className="col-sm-12">
                                <h1>PRIVACY POLICY</h1>
                                <p>
                                    The processing of personal data carried out on zimbel.org complies with the General Data Protection Regulations (GDPR).
                                </p>
                            </div>
                        </div>

                        <div className="row mt-20">
                            <div className="col-sm-12">
                                <ol>
                                    <li><a href="#paragraph-1">Politique de protection des données</a></li>
                                    <li><a href="#paragraph-2">Objet du traitement de données</a></li>
                                    <li><a href="#paragraph-3">Données traitées</a></li>
                                    <li><a href="#paragraph-4">Personnes concernées</a></li>
                                    <li><a href="#paragraph-5">Destinataires des données</a></li>
                                    <li><a href="#paragraph-6">Durée de conservation des données</a></li>
                                    <li><a href="#paragraph-7">Sécurité</a></li>
                                    <li><a href="#paragraph-8">Vos droits sur les données vous concernant</a></li>
                                    <li><a href="#paragraph-9">A propos des cookies</a></li>
                                    <ol>
                                        <li><a href="#paragraph-9-1">Cookies internes nécessaires au site pour fonctionner</a></li>
                                        <li><a href="#paragraph-9-2">Cookies tiers destinés à améliorer l’interactivité du site</a></li>
                                    </ol>
                                </ol>
                            </div>
                        </div>

                        <div className="row mt-20">
                            <div className="col-sm-12">
                                <h2 id="paragraph-1">1. Politique de protection des données</h2>
                                <p>
                                    Ce service en ligne limite la collecte des données personnelles au strict nécessaire (minimisation des données) et s’accompagne d’une information sur :
                                </p>
                                <ul>
                                    <li>le responsable du traitement et les objectifs du recueil de ces données (finalités),</li>
                                    <li>la base juridique du traitement de données,</li>
                                    <li>le caractère obligatoire ou facultatif du recueil des données pour la gestion de votre demande et le rappel des catégories de données traitées,</li>
                                    <li>la source des données (lorsque d’autres données que celles fournies via le service en ligne sont utilisées pour le traitement de votre demande),</li>
                                    <li>les catégories de personnes concernées,</li>
                                    <li>les destinataires des données (uniquement zimbel.org en principe, sauf précision lorsqu'une transmission à un tiers est nécessaire)</li>
                                    <li>la durée de conservation des données,</li>
                                    <li>les mesures de sécurité (description générale),</li>
                                    <li>l’existence éventuelle de transferts de données hors de l’Union européenne ou de prises de décision automatisées,</li>
                                    <li>vos droits Informatique et Libertés et la façon de les exercer auprès de la CNIL.</li>
                                </ul>
                                <p>
                                    Les données personnelles recueillies dans le cadre des services proposés sur cnil.fr sont traitées selon des protocoles sécurisés et permettent à la CNIL de gérer les demandes reçues dans ses applications informatiques.
                                </p>
                            </div>
                        </div>


                        <div className="row mt-20">
                            <div className="col-sm-12">
                                <h2 id="paragraph-2">2. Objet du traitement de données</h2>
                                <p>
                                    <b>Finalités</b><br/>
                                    Le traitement a pour objet la gestion des sites web administrés par la CNIL.<br/>
                                    Il permet :
                                </p>
                                <ul>
                                    <li>la préparation et la publication de contenus ;</li>
                                    <li>la mise en ligne des formulaires de contact (webforms) et des appels à contributions (consultations) ;</li>
                                    <li>l'administration technique en lien avec les prestataires (tierce maintenance applicative, hébergement, registraire de noms de domaine, autorité de certification) ;</li>
                                    <li>la gestion de la sécurité des sites (équipements et journaux de sécurité) ;</li>
                                    <li>la production de statistiques d’audience et d’utilisation des services en ligne proposés par la CNIL.</li>
                                </ul>

                                <p>
                                    <b>Base légale</b><br/>
                                    Article 6 (1) e du règlement européen 2016/679 (règlement général sur la protection des données - RGPD) – exercice de l’autorité publique dont est investie la CNIL en vertu du RGPD et de la loi Informatique et Libertés modifiée)
                                </p>
                            </div>
                        </div>

                        <div className="row mt-20">
                            <div className="col-sm-12">
                                <h2 id="paragraph-3">3. Données traitées</h2>
                                <p>
                                    <b>Catégories de données traitées</b>
                                </p>
                                <ul>
                                    <li>Identité, fonctions, coordonnées ;</li>
                                    <li>Données relatives à la navigation sur les sites web  (horodatage, adresse IP des usagers, données techniques relatives à l’équipement et au navigateur utilisés par les usagers, cookies) et sur les plateformes numériques via des boutons de partage et des médias (cookies et autres traceurs) ;</li>
                                    <li>Données relatives à la gestion des contacts usagers (horodatage et objet de la demande, suivi, suites apportées, statistiques) ;</li>
                                    <li>Données relatives à la gestion des publications (objet, livrable, suivi, statistiques) ;</li>
                                    <li>Données relatives à la gestion des prestations techniques (horodatage et objet des demandes, suivi, suites données, statistiques) ;</li>
                                    <li>Statistiques d’audience des sites web et d’utilisation des services en ligne proposés par la CNIL.</li>
                                </ul>

                                <p>
                                    <b>Source des données</b>
                                </p>
                                <ul>
                                    <li>Personnels de la CNIL en charge de la publication des contenus et de l’administration technique des sites web ;</li>
                                    <li>Contributeurs aux publications ;</li>
                                    <li>Usagers des sites web administrés par la CNIL ;</li>
                                    <li>Personnels des prestataires concernés.</li>
                                </ul>

                                <p>
                                    <b>Caractère obligatoire du recueil des données et conséquences en cas de non-fourniture des données.</b><br/>
                                    Les données collectées (notamment les cookies) à l’occasion de la navigation pour la mesure de l’audience des sites web et de l’utilisation des services en ligne présentent un caractère facultatif.<br/>
                                    Les données collectées pour la réalisation des autres finalités sont recueillies de façon obligatoire, sauf – dans le cas d’une collecte directe - mention contraire dans le formulaire concerné ou sur la plateforme numérique concernée.
                                </p>
                                <p>
                                    <b>Prise de décision automatisée</b><br/>
                                    Le traitement ne prévoit pas de prise de décision automatisée.
                                </p>
                            </div>
                        </div>

                        <div className="row mt-20">
                            <div className="col-sm-12">
                                <h2 id="paragraph-4">4. Personnes concernées</h2>
                                <p>
                                    Le traitement de données concerne :
                                </p>
                                <ul>
                                    <li>les personnels de la CNIL en charge de la publication des contenus et de l’administration technique des sites web ;</li>
                                    <li>les membres et personnels de la CNIL, ainsi que les tiers, identifiés dans les publications ;</li>
                                    <li>les usagers des sites web administrés par la CNIL ;</li>
                                    <li>les personnels des prestataires concernés.</li>
                                </ul>
                            </div>
                        </div>


                        <div className="row mt-20">
                            <div className="col-sm-12">
                                <h2 id="paragraph-5">5. Destinataires des données</h2>
                                <p>
                                    <b>Catégories de destinataires</b><br/>
                                    En fonction de leurs besoins respectifs, sont destinataires de tout ou partie des données :
                                </p>
                                <ul>
                                    <li>les personnels de la CNIL en charge de la publication des contenus et de l’administration technique des sites web, et leur hiérarchie ;</li>
                                    <li>les personnels de la CNIL chargés du traitement des demandes reçues via les formulaires de contact ;</li>
                                    <li>les personnels des prestataires concernés ;</li>
                                    <li>les usagers des sites web administrés par la CNIL ;</li>
                                    <li>les personnels responsables de la supervision de la sécurité des systèmes d’information de la CNIL.</li>
                                </ul>

                                <p>
                                    <b>Transferts des données hors UE</b><br/>
                                    Les publications sont susceptibles d’être accessibles, du fait de leur présence sur Internet, hors de l’Union européenne.
                                </p>
                            </div>
                        </div>

                        <div className="row mt-20">
                            <div className="col-sm-12">
                                <h2 id="paragraph-6">6. Durée de conservation des données</h2>
                                <ul>
                                    <li>Les données de préparation des publications (commande, suivi, contenu éditorial) sont conservées cinq ans à compter de la mise en ligne.</li>
                                    <li>Les données relatives aux échanges avec les prestataires sont conservées cinq ans au terme du marché concerné.</li>
                                    <li>Les données collectées via les webforms ou les consultations sont conservées sur une durée maximale de cinq ans à compter de leur réception.</li>
                                    <li>Les données des journaux (logs) sont conservées six mois.</li>
                                    <li>Les données nécessaires à la production de statistiques d’audience et d’utilisation des services en ligne (outil Piwik) sont conservées dans un format ne permettant pas l’identification des personnes par leur adresse IP, et comportent un identifiant (relatif au cookie) conservé pour une durée maximale de treize mois sauf opposition de la personne concernée.</li>
                                </ul>
                            </div>
                        </div>

                        <div className="row mt-20">
                            <div className="col-sm-12">
                                <h2 id="paragraph-7">7. Sécurité</h2>
                                <p>
                                    Les mesures de sécurité sont mises en œuvre conformément à la politique de sécurité des systèmes d’information (PSSI) de la CNIL, issue de la PSSI de l’Etat.
                                </p>
                            </div>
                        </div>

                        <div className="row mt-20">
                            <div className="col-sm-12">
                                <h2 id="paragraph-8">8. Vos droits sur les données vous concernant</h2>
                                <div className="box bg-pastel-default">
                                    <blockquote className="">
                                        <p>
                                            Vous pouvez accéder et obtenir copie des données vous concernant, vous opposer au traitement de ces données, les faire rectifier ou les faire effacer. Vous disposez également d'un droit à la limitation du traitement de vos données.
                                        </p>
                                    </blockquote>
                                </div>

                                <p>
                                    <b>Exercer ses droits</b><br/>
                                    Le délégué à la protection des données (DPO) de la CNIL est votre interlocuteur pour toute demande d'exercice de vos droits sur ce traitement.

                                    Contacter le DPO par voie électronique
                                    > Contacter le délégué à la protection des données (DPO) de la CNIL



                                </p>
                                <p>
                                    <b>Réclamation (plainte) auprès de la CNIL</b><br/>
                                    Si vous estimez, après nous avoir contactés, que vos droits sur vos données ne sont pas respectés, vous pouvez adresser une réclamation (plainte) à la CNIL.
                                </p>
                            </div>
                        </div>

                        <div className="row mt-20">
                            <div className="col-sm-12">
                                <h2 id="paragraph-9">9. A propos des cookies</h2>
                                <p>
                                    Nous utilisons différents cookies sur le site pour améliorer l’interactivité du site et  nos services.
                                </p>
                                <p>
                                    Qu'est-ce qu'un "cookie" ?<br/>
                                    Un "cookie" est une suite d'informations, généralement de petite taille et identifié par un nom, qui peut être transmis à votre navigateur par un site web sur lequel vous vous connectez. Votre navigateur web le conservera pendant une certaine durée, et le renverra au serveur web chaque fois que vous vous y re-connecterez. Les cookies ont de multiples usages : ils peuvent servir à mémoriser votre identifiant client auprès d'un site marchand, le contenu courant de votre panier d'achat, un identifiant permettant de tracer votre navigation pour des finalités statistiques ou publicitaires, etc.
                                </p>
                            </div>
                        </div>

                        <div className="row mt-20">
                            <div className="col-sm-12">
                                <h3 id="paragraph-9-1">9.1. Cookies internes nécessaires au site pour fonctionner</h3>
                                <p>
                                    Ces cookies permettent au site de fonctionner de manière optimale. Vous pouvez vous y opposer et les supprimer en utilisant les paramètres de votre navigateur, cependant votre expérience utilisateur risque d’être dégradée.
                                </p>
                                <table className="table">
                                    <thead className="thead-dark">
                                    <tr>
                                        <th scope="col">Nom du cookie</th>
                                        <th scope="col">Finalité</th>
                                        <th scope="col">Durée de conservation</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <th scope="row">xxxx</th>
                                        <td>yyyy</td>
                                        <td>zzzz</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="row mt-20">
                            <div className="col-sm-12">
                                <h3 id="paragraph-9-2">9.2. Cookies tiers destinés à améliorer l’interactivité du site.</h3>
                                <p>
                                    Le site de la CNIL s’appuie sur certains services proposés par des sites tiers. Il s’agit notamment :
                                </p>
                                <ul>
                                    <li>Des boutons de partage (twitter et facebook)</li>
                                    <li>Des listes de tweets (Twitter)</li>
                                    <li>Des vidéos diffusées sur le site (youtube, dailymotion)</li>
                                    <li>De présentations animées (Prezi)</li>
                                </ul>
                                <p>
                                    Ces fonctionnalités utilisent des cookies tiers directement déposés par ces services. Lors de votre première visite sur cnil.fr, un bandeau vous informe de la présence de ces cookies et vous invite à indiquer votre choix. Ils ne sont déposés que si vous les acceptez ou que vous poursuivez votre navigation sur le site en visitant une seconde page de cnil.fr. Vous pouvez à tout moment vous informer et paramétrer vos cookies pour les accepter ou les refuser en vous rendant sur la page [Gestion des cookies] présente en haut de chaque page du site. Vous pourrez indiquer votre préférence soit globalement pour le site, soit service par service.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>

                <FooterComponent/>
            </div>
        )
    };

    render() {

        switch (UserContext.locale.substring(0, 2)) {
            case 'fr':
                return this.renderFrench();
            default:
                return this.renderEnglish();
        }

    }
}

export default PrivacyPolicyPage;