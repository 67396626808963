import React from "react";
import {Link} from "react-router-dom";
import {HostContext} from "../App";
import NavigationComponent from "../components/NavigationComponent";
import FooterComponent from "../components/FooterComponent";
import ReactGA from "react-ga";
import IntlMessages from "../util/IntlMessages";
import {injectIntl} from "react-intl";
import Sticky from "react-stickynode";
import {ReactSVG} from "react-svg";
import {css} from "glamor";

class ClubsResultPage extends React.Component {

    state = {
        subTitle: "",
        clubs: [],
        isLoading: true,
    };

    componentDidMount = () => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    };

    componentWillMount() {
        const {messages} = this.props.intl;
        const params = new URLSearchParams(this.props.location.search);
        let url = "";
        if (params.get('text') != null) {
            const text = params.get('text');
            if ((text.length === 5) && Number.isInteger(parseInt(text, 10))) {
                url = HostContext.hostName + '/clubs?postal-code=' + text;
                this.setState({
                    subTitle: messages["exhibitions.result-postal-code"] + " " + text
                })
            } else {
                url = HostContext.hostName + '/clubs?city=' + text;
                this.setState({
                    subTitle: messages["exhibitions.result-city"] + " " + text
                })
            }
        } else if (params.get('dept') != null) {
            url = HostContext.hostName + '/clubs?dept=' + params.get('dept');
            this.setState({
                subTitle: messages["clubs.result-department"] + " " + params.get('dept')
            })
        } else {

        }

        fetch(url)
            .then(response => response.json())
            .then(json => {
                console.log(json);
                this.setState({
                    clubs: json,
                    isLoading: false
                });
            });
    }

    renderResult = () => {
        if (this.state.clubs.length < 1) {
            const styles = css({
                ' svg': {
                    height: 400,
                },
            });

            return (
                <div className="wrapper bg-pastel-default">
                    <div className="inner pt-70">
                        <div className="container">
                            <h1 className="heading text-center"><IntlMessages id="clubs.search"/></h1>
                            <h2 className="sub-heading2 text-center">{this.state.subTitle}</h2>
                        </div>
                        <div className="space50"></div>
                        <div className="clearfix"></div>
                        <div className="container">
                            <div className="row mb-50 border-top pt-20">
                                <div className="col-12">
                                    <div className="text-center">
                                        <p className="font-weight-bold"><IntlMessages id="clubs.search-none"/></p>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <ReactSVG src="/style/images/art/photographer.svg" {...styles} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="wrapper bg-pastel-default">
                <div className="container inner">
                    <h1 className="heading text-center"><IntlMessages id="clubs.search"/></h1>
                    <h2 className="sub-heading2 text-center">{this.state.subTitle}</h2>
                    <div className="space50"></div>
                    <div className="clearfix"></div>
                    <div className="row">

                        {this.state.clubs.map((club, index) => (
                            <div className="col-md-6 mb-30">
                                <Link to={"/clubs/" + club.name_url + "/" + club.id}>
                                    <div className="box bg-white shadow">
                                        <div className="d-flex flex-row ">
                                            <div className="icon fs-50 icon-color color-orange mr-25">
                                                <i className="si-photo_aperture"></i>
                                            </div>
                                            <div>
                                                <h5>{club.name}</h5>
                                                <p className="text-black-50">{club.address}<br/>
                                                    {club.postal_code} {club.city}</p>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}

                    </div>
                </div>
            </div>

        );
    };

    render() {
        return (
            <div>
                <Sticky enabled={true} innerZ={999999}>
                    <NavigationComponent/>
                </Sticky>

                {this.renderResult()}

                <div className="wrapper light-wrapper">
                    <div className="container inner text-center">
                        <h2 className="sub-heading text-center"><IntlMessages id="clubs.new-search"/></h2>
                        <div className="space10"></div>
                        <Link to="/clubs/search" className="btn btn-white shadow"><IntlMessages id="general.search"/></Link>
                    </div>
                </div>

                <FooterComponent/>
            </div>
        )
    }
}

export default injectIntl(ClubsResultPage);
