import L from 'leaflet';

const iconPosition = new L.Icon({
    iconUrl: require('./location.svg'),
    iconRetinaUrl: require('./location.svg'),
    iconAnchor: null,
    //popupAnchor: null,
    popupAnchor:  [0, -9],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(24, 32),
    className: ''
});

export { iconPosition };