import React from "react";
import ReactGA from "react-ga";
import Sticky from "react-stickynode";
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'
import NavigationComponent from "../components/NavigationComponent";
import { iconPosition } from './../icons/IconPosition';
import {HostContext} from "../App";

import "leaflet/dist/leaflet.css";
import FooterComponent from "../components/FooterComponent";

class ClubDetailPage extends React.Component {

    state = {
        dataLoaded: false,
        club: {
            name: "",
            address: "",
            complement: "",
            hamlet: "",
            postal_code: "",
            city: "",
            email: "",
            phone: "",
            website: "",
            lat: null,
            long: null
        }
    };

    constructor(props) {
        super(props);
        this.jquery = window.jQuery || window.$;
    }

    componentDidMount = () => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        this.loadData(this.props.match.params.id);

        this.jquery(document).ready(() => {

            this.jquery('#slider10').revolution({
                sliderType: "standard",
                sliderLayout: "fullscreen",
                spinner: "spinner2",
                delay: 9000,
                shadow: 0,
                gridwidth:[1140, 1024, 778, 480],
                responsiveLevels: [1240, 1024, 778, 480],
                disableProgressBar:"on",
                navigation: {
                    arrows: {
                        enable: true,
                        hide_onleave: true,
                        hide_under:1024,
                        style: 'ares',
                        tmp: ''
                    },
                    touch: {
                        touchenabled: 'on',
                        swipe_threshold: 75,
                        swipe_min_touches: 1,
                        swipe_direction: 'horizontal',
                        drag_block_vertical: true
                    },
                    tabs: {
                        style: 'zeus',
                        enable: true,
                        width: 50,
                        height: 50,
                        min_width: 50,
                        wrapper_padding: 0,
                        wrapper_color: 'none',
                        wrapper_opacity: '0.5',
                        tmp: '<span class="tp-tab-image"></span>',
                        visibleAmount: 20,
                        hide_onmobile: true,
                        hide_onleave: false,
                        hide_delay: 200,
                        direction: 'horizontal',
                        span: true,
                        position: 'inner',
                        space: 5,
                        h_align: 'center',
                        v_align: 'bottom',
                        h_offset: 20,
                        v_offset: 20
                    },
                    bullets: {
                        enable: false
                    }
                }
            });

        });
    };

    loadData = (id) => {
        fetch(HostContext.hostName + '/clubs/' + id)
            .then(response => response.json())
            .then(json => {
                console.log(json);
                this.setState({
                    club: json,
                    dataLoaded: true
                });
            });
    };

    renderOld() {
        return (
            <div className="content-wrapper">

                <Sticky enabled={true} innerZ={999999}>
                    <NavigationComponent/>
                </Sticky>

                <div className="rev_slider_wrapper fullscreen-container">
                    <div id="slider10" className="rev_slider fullscreenbanner" data-version="5.4.7">
                        <ul>
                            <li data-transition="fade" data-thumb="style/images/art/slider-bg63.jpg"><img
                                src="style/images/art/slider-bg63.jpg" alt=""/></li>
                            <li data-transition="fade" data-thumb="style/images/art/slider-bg64.jpg"><img
                                src="style/images/art/slider-bg64.jpg" alt=""/></li>
                            <li data-transition="fade" data-thumb="style/images/art/slider-bg65.jpg"><img
                                src="style/images/art/slider-bg65.jpg" alt=""/></li>
                            <li data-transition="fade" data-thumb="style/images/art/slider-bg66.jpg"><img
                                src="style/images/art/slider-bg66.jpg" alt=""/></li>
                        </ul>
                        <div className="tp-bannertimer tp-bottom"></div>
                    </div>
                </div>



                <div class="wrapper light-wrapper">
                    <div class="container inner pt-60">
                        <h1 className="heading text-center">Journal</h1>
                        <p className="lead larger text-center">Hi! I’m Jonathan, a documentary wedding photographer
                            currently working from and living in London.<br className="d-none d-lg-block"/> I absolutely
                            love shooting weddings and couples because there are so much
                            feeling to capture.</p>
                        <div className="space30"></div>

                        <div class="blog grid grid-view boxed">
                            <div class="row isotope">
                                <div class="item post grid-sizer col-md-6 col-lg-4">
                                    <div class="box bg-white shadow p-30">
                                        <figure class="main mb-30 overlay overlay1 rounded"><a href="#"> <img src="style/images/art/gb1.jpg" alt="" /></a>
                                            <figcaption>
                                                <h5 class="text-uppercase from-top mb-0">Read More</h5>
                                            </figcaption>
                                        </figure>
                                        <div class="meta mb-10"><span class="category"><a href="#" class="hover color">Reflection</a></span></div>
                                        <h2 class="post-title"><a href="blog-post.html">Ligula tristique quis risus eget urna mollis ornare porttitor</a></h2>
                                        <div class="post-content">
                                            <p>Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Cum sociis natoque.</p>
                                        </div>
                                        <hr />
                                        <div class="meta meta-footer d-flex justify-content-between mb-0"><span class="date">5 Jul 2018</span><span class="comments"><a href="#">3</a></span></div>
                                    </div>
                                </div>
                                <div class="item post grid-sizer col-md-6 col-lg-4">
                                    <div class="box bg-white shadow p-30">
                                        <figure class="main mb-30 overlay overlay1 rounded"><a href="#"> <img src="style/images/art/gb2.jpg" alt="" /></a>
                                            <figcaption>
                                                <h5 class="text-uppercase from-top mb-0">Read More</h5>
                                            </figcaption>
                                        </figure>
                                        <div class="meta mb-10"><span class="category"><a href="#" class="hover color">Entertainment</a></span></div>
                                        <h2 class="post-title"><a href="blog-post.html">Nullam id dolor elit id nibh pharetra augue venenatis</a></h2>
                                        <div class="post-content">
                                            <p>Aenean lacinia bibendum nulla sed consectetur. Integer posuere erat a ante porttitor mollis sagittis lacus ultricies.</p>
                                        </div>
                                        <hr />
                                        <div class="meta meta-footer d-flex justify-content-between mb-0"><span class="date">18 Jun 2018</span><span class="comments"><a href="#">5</a></span></div>
                                    </div>
                                </div>
                                <div class="item post grid-sizer col-md-6 col-lg-4">
                                    <div class="box bg-white shadow p-30">
                                        <figure class="main mb-30 overlay overlay1 rounded"><a href="#"> <img src="style/images/art/gb3.jpg" alt="" /></a>
                                            <figcaption>
                                                <h5 class="text-uppercase from-top mb-0">Read More</h5>
                                            </figcaption>
                                        </figure>
                                        <div class="meta mb-10"><span class="category"><a href="#" class="hover color">Travel</a></span></div>
                                        <h2 class="post-title"><a href="blog-post.html">Ultricies fusce porta elit pharetra augue faucibus</a></h2>
                                        <div class="post-content">
                                            <p>Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Maecenas faucibus. Tellus vulputate non magna eget urna.</p>
                                        </div>
                                        <hr />
                                        <div class="meta meta-footer d-flex justify-content-between mb-0"><span class="date">14 May 2018</span><span class="comments"><a href="#">7</a></span></div>
                                    </div>
                                </div>
                                <div class="item post grid-sizer col-md-6 col-lg-4">
                                    <div class="box bg-white shadow p-30">
                                        <figure class="main mb-30 overlay overlay1 rounded"><a href="#"> <img src="style/images/art/gb4.jpg" alt="" /></a>
                                            <figcaption>
                                                <h5 class="text-uppercase from-top mb-0">Read More</h5>
                                            </figcaption>
                                        </figure>
                                        <div class="meta mb-10"><span class="category"><a href="#" class="hover color">Beach</a></span></div>
                                        <h2 class="post-title"><a href="blog-post.html">Morbi leo risus porta eget metus est non commodolacus</a></h2>
                                        <div class="post-content">
                                            <p>Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Donec sed odio dui. Curabitur blandit tempus.</p>
                                        </div>
                                        <hr />
                                        <div class="meta meta-footer d-flex justify-content-between mb-0"><span class="date">9 Apr 2018</span><span class="comments"><a href="#">4</a></span></div>
                                    </div>
                                </div>
                                <div class="item post grid-sizer col-md-6 col-lg-4">
                                    <div class="box bg-white shadow p-30">
                                        <figure class="main mb-30 overlay overlay1 rounded"><a href="#"> <img src="style/images/art/gb5.jpg" alt="" /></a>
                                            <figcaption>
                                                <h5 class="text-uppercase from-top mb-0">Read More</h5>
                                            </figcaption>
                                        </figure>
                                        <div class="meta mb-10"><span class="category"><a href="#" class="hover color">Travel</a></span></div>
                                        <h2 class="post-title"><a href="blog-post.html">Mollis adipiscing lorem quis mollis eget lacinia faucibus</a></h2>
                                        <div class="post-content">
                                            <p>Curabitur blandit tempus porttitor. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor rutrum.</p>
                                        </div>
                                        <hr />
                                        <div class="meta meta-footer d-flex justify-content-between mb-0"><span class="date">23 Feb 2018</span><span class="comments"><a href="#">8</a></span></div>
                                    </div>
                                </div>
                                <div class="item post grid-sizer col-md-6 col-lg-4">
                                    <div class="box bg-white shadow p-30">
                                        <figure class="main mb-30 overlay overlay1 rounded"><a href="#"> <img src="style/images/art/gb6.jpg" alt="" /></a>
                                            <figcaption>
                                                <h5 class="text-uppercase from-top mb-0">Read More</h5>
                                            </figcaption>
                                        </figure>
                                        <div class="meta mb-10"><span class="category"><a href="#" class="hover color">Building</a></span></div>
                                        <h2 class="post-title"><a href="blog-post.html">Fusce mattis euismod sed diam eget risus amet tempus</a></h2>
                                        <div class="post-content">
                                            <p>Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Curabitur blandit tempus porttitor nullam dolor.</p>
                                        </div>
                                        <hr />
                                        <div class="meta meta-footer d-flex justify-content-between mb-0"><span class="date">15 Jan 2018</span><span class="comments"><a href="#">7</a></span></div>
                                    </div>
                                </div>
                                <div class="item post grid-sizer col-md-6 col-lg-4">
                                    <div class="box bg-white shadow p-30">
                                        <figure class="main mb-30 overlay overlay1 rounded"><a href="#"> <img src="style/images/art/gb7.jpg" alt="" /></a>
                                            <figcaption>
                                                <h5 class="text-uppercase from-top mb-0">Read More</h5>
                                            </figcaption>
                                        </figure>
                                        <div class="meta mb-10"><span class="category"><a href="#" class="hover color">Travel</a></span></div>
                                        <h2 class="post-title"><a href="blog-post.html">Tellus vulputate non magna eget urna mollis lacinia mollis</a></h2>
                                        <div class="post-content">
                                            <p>Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Nullam quis risus eget gravida.</p>
                                        </div>
                                        <hr />
                                        <div class="meta meta-footer d-flex justify-content-between mb-0"><span class="date">12 Dec 2017</span><span class="comments"><a href="#">3</a></span></div>
                                    </div>
                                </div>
                                <div class="item post grid-sizer col-md-6 col-lg-4">
                                    <div class="box bg-white shadow p-30">
                                        <figure class="main mb-30 overlay overlay1 rounded"><a href="#"> <img src="style/images/art/gb8.jpg" alt="" /></a>
                                            <figcaption>
                                                <h5 class="text-uppercase from-top mb-0">Read More</h5>
                                            </figcaption>
                                        </figure>
                                        <div class="meta mb-10"><span class="category"><a href="#" class="hover color">Perspective</a></span></div>
                                        <h2 class="post-title"><a href="blog-post.html">Elit parturient tristique ornare vel ornare posuere porttitor</a></h2>
                                        <div class="post-content">
                                            <p> luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Duis mollis, est non commodo luctus nisi erat porttitor.</p>
                                        </div>
                                        <hr />
                                        <div class="meta meta-footer d-flex justify-content-between mb-0"><span class="date">25 Nov 2017</span><span class="comments"><a href="#">2</a></span></div>
                                    </div>
                                </div>
                                <div class="item post grid-sizer col-md-6 col-lg-4">
                                    <div class="box bg-white shadow p-30">
                                        <figure class="main mb-30 overlay overlay1 rounded"><a href="#"> <img src="style/images/art/gb9.jpg" alt="" /></a>
                                            <figcaption>
                                                <h5 class="text-uppercase from-top mb-0">Read More</h5>
                                            </figcaption>
                                        </figure>
                                        <div class="meta mb-10"><span class="category"><a href="#" class="hover color">Music</a></span></div>
                                        <h2 class="post-title"><a href="blog-post.html">Condimentum vehicula vitae elit libero lacinia interdum</a></h2>
                                        <div class="post-content">
                                            <p>Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Maecenas faucibus mollis interdum. Nulla vitae elit libero.</p>
                                        </div>
                                        <hr />
                                        <div class="meta meta-footer d-flex justify-content-between mb-0"><span class="date">4 Oct 2017</span><span class="comments"><a href="#">5</a></span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="space30 d-block d-md-none"></div>
                        <div class="pagination bg text-center">
                            <ul>
                                <li><a href="#" class="btn btn-white shadow"><i class="mi-arrow-left"></i></a></li>
                                <li class="active"><a href="#" class="btn btn-white shadow"><span>1</span></a></li>
                                <li><a href="#" class="btn btn-white shadow"><span>2</span></a></li>
                                <li><a href="#" class="btn btn-white shadow"><span>3</span></a></li>
                                <li><a href="#" class="btn btn-white shadow"><i class="mi-arrow-right"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>



                <div className="wrapper light-wrapper">
                    <div className="container inner">
                        <h1 className="heading text-center">Jonathan Missio Photography</h1>
                        <p className="lead larger text-center">Hi! I’m Jonathan, a documentary wedding photographer
                            currently working from and living in London.<br className="d-none d-lg-block"/> I absolutely
                            love shooting weddings and couples because there are so much
                            feeling to capture.</p>
                        <div className="space30"></div>
                        <div className="tiles grid">
                            <div className="items row align-items-start isotope boxed grid-view text-center">
                                <div className="item grid-sizer col-md-6">
                                    <div className="box bg-pastel-default p-30">
                                        <figure className="main mb-20 overlay overlay1 rounded"><a
                                            href="gallery-post.html"><img src="style/images/art/about9.jpg" alt=""/></a>
                                            <figcaption>
                                                <h5 className="text-uppercase from-top mb-0">Learn More</h5>
                                            </figcaption>
                                        </figure>
                                        <h5 className="mb-0">About</h5>
                                    </div>
                                </div>
                                <div className="item grid-sizer col-md-6">
                                    <div className="box bg-pastel-default p-30">
                                        <figure className="main mb-20 overlay overlay1 rounded"><a
                                            href="gallery-post2.html"><img src="style/images/art/f16.jpg" alt=""/></a>
                                            <figcaption>
                                                <h5 className="text-uppercase from-top mb-0">Featured Shots</h5>
                                            </figcaption>
                                        </figure>
                                        <h5 className="mb-0">Portfolio</h5>
                                    </div>
                                </div>
                                <div className="item grid-sizer col-md-3">
                                    <div className="box bg-pastel-default p-30">
                                        <figure className="main mb-20 overlay overlay1 rounded"><a
                                            href="gallery-post3.html"><img src="style/images/art/f17.jpg" alt=""/></a>
                                            <figcaption>
                                                <h5 className="text-uppercase from-top mb-0">Read Entries</h5>
                                            </figcaption>
                                        </figure>
                                        <h5 className="mb-0">Journal</h5>
                                    </div>
                                </div>
                                <div className="item grid-sizer col-md-3">
                                    <div className="box bg-pastel-default p-30">
                                        <figure className="main mb-20 overlay overlay1 rounded"><a
                                            href="gallery-post4.html"><img src="style/images/art/f18.jpg" alt=""/></a>
                                            <figcaption>
                                                <h5 className="text-uppercase from-top mb-0">Get in Touch</h5>
                                            </figcaption>
                                        </figure>
                                        <h5 className="mb-0">Contact</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wrapper bg-pastel-default">
                    <div className="container inner">
                        <h2 className="section-title text-center">Featured Shots</h2>
                        <p className="lead larger text-center">Photography is my passion and I love to turn ideas into
                            beautiful things.</p>
                        <div className="space30"></div>
                        <div className="flickity-carousel-container fullscreen">
                            <div className="flickity flickity-carousel">
                                <div className="item mr-15"><img src="style/images/art/cw10.jpg" alt=""/></div>
                                <div className="item mr-15"><img src="style/images/art/cw11.jpg" alt=""/></div>
                                <div className="item mr-15"><img src="style/images/art/cw12.jpg" alt=""/></div>
                                <div className="item mr-15"><img src="style/images/art/cw13.jpg" alt=""/></div>
                                <div className="item mr-15"><img src="style/images/art/cw14.jpg" alt=""/></div>
                                <div className="item mr-15"><img src="style/images/art/cw15.jpg" alt=""/></div>
                                <div className="item mr-15"><img src="style/images/art/cw16.jpg" alt=""/></div>
                                <div className="item mr-15"><img src="style/images/art/cw17.jpg" alt=""/></div>
                                <div className="item mr-15"><img src="style/images/art/cw18.jpg" alt=""/></div>
                            </div>
                            <p className="flickity-status"></p>
                        </div>
                    </div>
                </div>
                <div className="wrapper image-wrapper bg-image inverse-text" data-image-src="style/images/art/bg16.jpg">
                    <div className="container inner pt-150 pb-150">
                        <h1 className="heading text-center">I take photographs with<br
                            className="d-none d-lg-block"/> creativity, concept & passion</h1>
                    </div>
                </div>
                <div className="wrapper light-wrapper">
                    <div className="container inner">
                        <h2 className="section-title text-center">My Specialties</h2>
                        <p className="lead larger text-center">I specialize in wedding photography, but I also love
                            photographing couples and engagements.</p>
                        <div className="space30"></div>
                        <div className="tiles">
                            <div className="items row boxed grid-view text-center">
                                <div className="item col-md-4">
                                    <div className="box bg-pastel-default p-30">
                                        <figure className="main mb-20"><img src="style/images/art/si35.jpg" alt=""/>
                                        </figure>
                                        <h5 className="mb-0">Wedding</h5>
                                    </div>
                                </div>
                                <div className="item col-md-4">
                                    <div className="box bg-pastel-default p-30">
                                        <figure className="main mb-20"><img src="style/images/art/si36.jpg" alt=""/>
                                        </figure>
                                        <h5 className="mb-0">Engagement</h5>
                                    </div>
                                </div>
                                <div className="item col-md-4">
                                    <div className="box bg-pastel-default p-30">
                                        <figure className="main mb-20"><img src="style/images/art/si10.jpg" alt=""/>
                                        </figure>
                                        <h5 className="mb-0">Couples</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="wrapper dark-wrapper inverse-text">
                    <div className="container inner">
                        <h2 className="section-title text-center">Behind the Scenes</h2>
                        <p className="lead larger text-center">I would like to give you a unique photography and video
                            experience, built to serve you best and<br className="d-none d-xl-block"/> capture your
                            special moments for you and your families creatively and
                            beautifully</p>
                        <div className="space30"></div>
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <div className="player" data-plyr-provider="vimeo" data-plyr-embed-id="94837904"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="wrapper light-wrapper">
                    <div className="container inner">
                        <h2 className="section-title mb-40 text-center">Notes from Love Bugs</h2>
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <div className="cube-slider cbp-slider-edge cbp">
                                    <div className="cbp-item pl-60 pr-60 pb-10">
                                        <div className="row d-flex">
                                            <div className="col-md-6 pr-35 pr-sm-15">
                                                <figure><img src="style/images/art/te1.jpg" alt=""/></figure>
                                            </div>
                                            <div className="col-md-6 align-self-center">
                                                <blockquote className="icon icon-left">
                                                    <p>Consectetur adipiscing elit. Duis mollis, est non commodo luctus
                                                        gestas eget quam integer. Curabitur blandit tempus rutrum
                                                        faucibus. Integer posuere erat a ante venenatis dapibus posuere
                                                        velit aliquet.</p>
                                                    <footer className="blockquote-footer">Mildred & Austin</footer>
                                                </blockquote>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cbp-item pl-60 pr-60 pb-10">
                                        <div className="row d-flex">
                                            <div className="col-md-6 pr-35 pr-sm-15">
                                                <figure><img src="style/images/art/te2.jpg" alt=""/></figure>
                                            </div>
                                            <div className="col-md-6 align-self-center">
                                                <blockquote className="icon icon-left">
                                                    <p>Consectetur adipiscing elit. Duis mollis, est non commodo luctus
                                                        gestas eget quam integer. Curabitur blandit tempus rutrum
                                                        faucibus. Integer posuere erat a ante venenatis dapibus posuere
                                                        velit aliquet.</p>
                                                    <footer className="blockquote-footer">Mildred & Austin</footer>
                                                </blockquote>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cbp-item pl-60 pr-60 pb-10">
                                        <div className="row d-flex">
                                            <div className="col-md-6 pr-35 pr-sm-15">
                                                <figure><img src="style/images/art/te3.jpg" alt=""/></figure>
                                            </div>
                                            <div className="col-md-6 align-self-center">
                                                <blockquote className="icon icon-left">
                                                    <p>Consectetur adipiscing elit. Duis mollis, est non commodo luctus
                                                        gestas eget quam integer. Curabitur blandit tempus rutrum
                                                        faucibus. Integer posuere erat a ante venenatis dapibus posuere
                                                        velit aliquet.</p>
                                                    <footer className="blockquote-footer">Mildred & Austin</footer>
                                                </blockquote>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer className="dark-wrapper inverse-text">
                    <div className="container inner">
                        <div className="row d-md-flex align-items-md-center">
                            <div className="col-md-4 text-center text-md-left">
                                <p className="mb-0">© 2019 Missio. All rights reserved.</p>
                            </div>
                            <div className="col-md-4 text-center">
                                <img src="#" srcSet="style/images/logo-light.png 1x, style/images/logo-light@2x.png 2x"
                                     alt=""/>
                            </div>
                            <div className="col-md-4 text-center text-md-right">
                                <ul className="social social-mute social-s mt-10">
                                    <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                    <li><a href="#"><i className="fa fa-facebook-f"></i></a></li>
                                    <li><a href="#"><i className="fa fa-pinterest"></i></a></li>
                                    <li><a href="#"><i className="fa fa-vimeo"></i></a></li>
                                    <li><a href="#"><i className="fa fa-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        )
    }

    formatAddress(club) {
        let address = club.address;
        if (club.complement.length > 0) {
            address += ", " + club.complement
        }

        if (club.hamlet.length > 0) {
            address += ", " + club.hamlet
        }

        if (club.postal_code.length > 0) {
            address += ", " + club.postal_code;
            if (club.city.length > 0) {
                address += " " + club.city
            }
        } else {
            if (club.city.length > 0) {
                address += ", " + club.city
            }
        }

        return (
            <span>
                {address}
            </span>
        )
    }

    renderTest() {
        const position = [51.505, -0.09]

        return (
            <Map center={position} zoom={13}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                />
                <Marker position={position} icon={ iconPosition }>
                    <Popup>A pretty CSS3 popup.<br />Easily customizable.</Popup>
                </Marker>
            </Map>

        )
    }

    render() {
        const position = [50.62816619873047, 1.657185435295105]

        return (

            <div className="content-wrapper">

                <Sticky enabled={true} innerZ={999999}>
                    <NavigationComponent/>
                </Sticky>

                <div className="wrapper light-wrapper">
                    <div className="container inner">
                        <h1 className="heading text-center">{this.state.club.name}</h1>
                    </div>
                </div>

                <div className="wrapper light-wrapper">
                    <div className="container inner pt-30">
                        <div className="boxed">
                            <div className="bg-white shadow rounded">
                                <div className="image-block-wrapper">
                                    <div className="image-block col-lg-6 p-0">
                                        <div className="google-map map-full">
                                            {this.state.club.lat && this.state.club.long &&
                                            <Map center={[this.state.club.lat, this.state.club.long]} zoom={13} style={{height: 348, width: 600}}>
                                                <TileLayer
                                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                    attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                                                />
                                                <Marker position={[this.state.club.lat, this.state.club.long]} icon={ iconPosition }>
                                                    <Popup>{this.state.club.name}</Popup>
                                                </Marker>
                                            </Map>
                                            }
                                        </div>
                                    </div>
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-lg-6 offset-lg-6">
                                                <div className="box d-flex">
                                                    <div className="align-self-center">
                                                        <h4>Coordonnées</h4>
                                                        <p>Fusce dapibus, tellus commodo, tortor mauris condimentum
                                                            utellus fermentum, porta sem malesuada magna. Sed posuere
                                                            consectetur est at lobortis. Morbi leo risus, porta ac
                                                            consectetur.</p>
                                                        <ul className="icon-list">
                                                            <li>
                                                                <i className="fa fa-map" />{this.formatAddress(this.state.club)}
                                                            </li>
                                                            {this.state.club.email.length > 0 &&
                                                            <li><i className="fa fa-envelope"/>
                                                                <a
                                                                    href={"mailto:"+this.state.club.email}
                                                                    className="nocolor">{this.state.club.email}
                                                                </a>
                                                            </li>
                                                            }
                                                            {this.state.club.phone.length > 0 &&
                                                            <li><i className="fa fa-phone-square"/>
                                                                <a
                                                                    href={"tel:"+this.state.club.phone}
                                                                    className="nocolor">{this.state.club.phone}
                                                                </a>
                                                            </li>
                                                            }
                                                            {this.state.club.website.length > 0 &&
                                                            <li><i className="fa fa-link"/>
                                                                <a
                                                                    href={this.state.club.website}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    className="nocolor">{this.state.club.website}
                                                                </a>
                                                            </li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="wrapper white-wrapper">
                    <div className="container inner">
                        <h2 className="section-title mb-40 text-center">Personnalisez cette page</h2>
                        <p class="lead text-center">Si vous êtes un des membres du club, en vous inscrivant, vous pouvez personnaliser cette page.</p>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="box bg-white shadow" style={{minHeight: 206}}>
                                    <div className="d-flex flex-row justify-content-center">
                                        <div className="icon fs-50 icon-color color-rose mr-25">
                                            <i className="si-building_home-2" />
                                        </div>
                                        <div>
                                            <h5>Page d'accueil</h5>
                                            <p>Vous pouvez choisir votre page d'accueil parmi nos modèles adaptés à la communication de votre club.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="box bg-white shadow">
                                    <div className="d-flex flex-row justify-content-center">
                                        <div className="icon fs-50 icon-color color-blue mr-25">
                                            <i className="si-photo_landscape-picture" />
                                        </div>
                                        <div>
                                            <h5>Portfolio</h5>
                                            <p>Vous pouvez publier votre portfolio et mettre en valeur les photographies résultats des travaux des membres de votre club.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="space30 d-none d-md-block"></div>
                            <div className="col-md-6">
                                <div className="box bg-white shadow" style={{minHeight: 206}}>
                                    <div className="d-flex flex-row justify-content-center">
                                        <div className="icon fs-50 icon-color color-green mr-25">
                                            <i className="si-calendar_calendar-days" />
                                        </div>
                                        <div>
                                            <h5>Evénements</h5>
                                            <p>Vous pouvez communiquer à vos membres les dates des événements organisés par votre club.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="box bg-white shadow">
                                    <div className="d-flex flex-row justify-content-center">
                                        <div className="icon fs-50 icon-color color-purple mr-25">
                                            <i className="si-ui_edit" />
                                        </div>
                                        <div>
                                            <h5>Blog</h5>
                                            <p>Vous pouvez publier des articles sur l'actualité de votre club et partager des informations concernant la photographie.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="wrapper light-wrapper">
                    <div className="container inner">
                        <h2 className="section-title mb-40 text-center">Exemples de pages d'accueil</h2>
                        <div className="tiles">
                            <div className="items row boxed grid-view text-center">
                                <div className="item col-md-4">
                                    <div className="box bg-white shadow p-30">
                                        <figure className="main mb-30"><img src="style/images/art/si4.jpg" alt=""/>
                                        </figure>
                                        <h4>Commercial Photography</h4>
                                        <p className="mb-10">Fusce dapibus tellus ac cursus tortor mauris condimentum
                                            nibh.</p>
                                        <div className="arrow-link mb-10"><a href="#" className="text-muted">See
                                            Gallery <i className="mi-arrow-right"></i></a></div>
                                    </div>
                                </div>
                                <div className="item col-md-4">
                                    <div className="box bg-white shadow p-30">
                                        <figure className="main mb-30"><img src="style/images/art/si13.jpg" alt=""/>
                                        </figure>
                                        <h4>Events Photography</h4>
                                        <p className="mb-10">Fusce dapibus tellus ac cursus tortor mauris condimentum
                                            nibh.</p>
                                        <div className="arrow-link mb-10"><a href="#" className="text-muted">See
                                            Gallery <i className="mi-arrow-right"></i></a></div>
                                    </div>
                                </div>
                                <div className="item col-md-4">
                                    <div className="box bg-white shadow p-30">
                                        <figure className="main mb-30"><img src="style/images/art/si14.jpg" alt=""/>
                                        </figure>
                                        <h4>Fashion Photography</h4>
                                        <p className="mb-10">Fusce dapibus tellus ac cursus tortor mauris condimentum
                                            nibh.</p>
                                        <div className="arrow-link mb-10"><a href="#" className="text-muted">See
                                            Gallery <i className="mi-arrow-right"></i></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="wrapper gray-wrapper">
                    <div className="container inner pt-70">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                                <h2 className="section-title text-center">S'inscrire</h2>
                                <p className="text-center">Merci d'indiquer votre adresse de messagerie. Vous recevrez un message contenant le lien vous permettant de poursuivre votre inscription.</p>
                                <div className="space20"></div>
                                <div className="form-container">
                                    <form action="contact/vanilla-form.php" method="post"
                                          className="vanilla vanilla-form" noValidate>
                                        <div className="row text-center">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <input type="email" className="form-control" name="email"
                                                           placeholder="Your e-mail" required="required" />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <button type="submit" className="btn" data-error="Fix errors"
                                                        data-processing="Sending..." data-success="Thank you!">Submit
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <FooterComponent/>


            </div>
        )
    }
}

export default ClubDetailPage;