import React from "react";
import ReactGA from "react-ga";
import NavigationComponent from "../components/NavigationComponent";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import FooterComponent from "../components/FooterComponent";
import AlphaPagination from "../components/AlphaPagination";
import {HostContext} from "../App";
import {NavLink} from "react-router-dom";
import IntlMessages from "../util/IntlMessages";

class PhotographerSearchPage extends React.Component {

    state = {
        currentPage: "A",
        tags: []
    };

    componentDidMount = () => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        fetch(HostContext.hostName + '/tags')
            .then(response => response.json())
            .then(json => {
                this.setState({tags: json});
            });
    };

    onChangePage = (page) => {
        this.props.history.push('/photographers/' + page);
    };

    handleSubmit = async (values, { setErrors }) => {
        this.props.history.push("/photographers/name/" + values['text']);
    };

    render() {
        const SearchSchema = Yup.object().shape({
            text: Yup.string()
                .required("Ce champ est requis."),
        });

        return (
            <div className="content-wrapper">
                <NavigationComponent/>

                <div className="wrapper bg-pastel-default">
                    <div className="container inner">
                        <h2 className="section-title text-center"><IntlMessages id="photographer.search-name"/></h2>
                        <div className="space20"></div>

                        <div className="form-container">

                            <Formik
                                initialValues={{
                                    text: '',
                                }}
                                validationSchema={SearchSchema}
                                onSubmit={this.handleSubmit}
                                isInitialValid={false}
                            >
                                {({ errors, touched, isValid }) => (
                                    <Form className="vanilla vanilla-form" noValidate>
                                        <div className="row text-center">
                                            <div className="col-12">
                                                <Field type="text" name="text" className="form-control" spellcheck="false"/>
                                                {errors.text && touched.text ? (<div className="form-error">{errors.text}</div>) : null}
                                                <div className="space20"></div>
                                                <button type="submit" className="btn" disabled={!isValid}><IntlMessages id="general.search"/></button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>

                            <div className="space30"></div>
                            <AlphaPagination
                                currentPage={this.state.currentPage}
                                onChangePage={i => this.onChangePage(i)}
                            />

                        </div>
                    </div>
                </div>

                <div className="wrapper white-wrapper">
                    <div className="container inner">
                        <h2 className="section-title text-center"><IntlMessages id="photographer.search-tag"/></h2>
                        <div className="space30"></div>

                        <div className="text-center">
                            {this.state.tags.map((tag, index) => {
                                return (
                                    <NavLink key={index} to={"/photographers/tag/" + tag.label + "/" + tag.value}>
                                        <span className="badge badge-pill bg-pastel-orange ml-10 mb-10" key={tag.value}>{tag.label}</span>
                                    </NavLink>
                                )
                            })}
                        </div>
                    </div>
                </div>

                <FooterComponent/>

            </div>
        )
    }
}

export default PhotographerSearchPage;