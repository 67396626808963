import React from "react";
import {withRouter} from "react-router-dom";
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "reactstrap";
import {FormattedMessage, injectIntl} from "react-intl";
import IntlMessages from "../util/IntlMessages";
import {HostContext} from "../App";
import {Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import PropTypes from "prop-types";
import PrivacyComponent from "../components/PrivacyComponent";
import * as ls from "local-storage";

class LoginForm extends React.Component {

    state = {
        modalPrivacyOpen: false,
        modalTermsOpen: false
    };

    constructor(props) {
        super(props);
        this.state = this.props.state;
    }

    handleSubmit = async (values, { setErrors }) => {
        let url = HostContext.hostName + '/users/signup';

        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(values)
        }).then(function(response) {
            const statusCode = response.status;
            const data = response.json();
            return Promise.all([statusCode, data]);
        }).then(function([statusCode, data]) {
            switch (statusCode) {
                case 200:
                    /*
                    UserContext.isLogged = true;
                    UserContext.access_token = data.access_token;
                    UserContext.mapkit_token = data.mapkit_token;
                    UserContext.user = data.user;
                    const userInfo = {
                        access_token: UserContext.access_token,
                        mapkit_token: UserContext.mapkit_token,
                        user: UserContext.user,
                    }
                    ls.set('zimbel-backend-user', userInfo);
                    that.props.history.push('/');
                    */
                    break;
                case 422:
                    data.forEach(function(element) {
                        setErrors({ [element.field]: element.message });
                    });
                    break;
                default:
                    setErrors({ 'general_error': 'Status code ' + statusCode });
                    break;
            }
        }).catch(function(error) {
            setErrors({ 'general_error': error.message });
        });

/*
        }).then(response => {
            if (response.ok) {
                return response.json()
            }
            switch (response.status) {
                case 401:
                    throw Error(response.statusText);
                default:
                    throw Error(response.statusText);
            }
        }).then(json => {
            this.props.toggle(true);
            //this.dataListRender();
            //this.toggleModal();
        }).catch(function (error) {
            console.log(error);
            //that.setState({'requestError': error.message});
        });
*/
    };

    togglePrivacyModal = () => {
        this.setState({
            modalPrivacyOpen: !this.state.modalPrivacyOpen
        });
    };

    toggleTermsModal = () => {
        this.setState({
            modalTermsOpen: !this.state.modalTermsOpen
        });
    };

    render = () => {
        const {messages} = this.props.intl;

        const LoginSchema = Yup.object().shape({
            email: Yup.string().email(messages['general.email-invalid']).required(messages['general.field-required']),
            password: Yup.string().required(messages['general.field-required']).min(12, messages['user.password-length']),
        });

        return (
            <Formik
                initialValues={{
                    email: '',
                    password: ''
                }}
                validationSchema={LoginSchema}
                validateOnMount={false}
                onSubmit={values => {
                    this.handleSubmit(values).then(() => {});
                }}
            >
                {({ errors, touched, isValid }) => (
                    <Form>
                        <ModalHeader toggle={this.props.toggle}>
                            <IntlMessages id="user.login-title"/>
                        </ModalHeader>

                        <ModalBody>
                            <div className="col-md-12 pr-10">
                                <div className="form-group">
                                    <Field type="email"
                                           name="email"
                                           className="form-control"
                                           placeholder={messages["user.email"]}
                                    />
                                    <ErrorMessage name="email" />
                                </div>
                            </div>

                            <div className="col-md-12 pr-10">
                                <div className="form-group">
                                    <Field type="password"
                                           name="password"
                                           className="form-control"
                                           placeholder={messages["user.password"]}
                                    />
                                    <ErrorMessage name="password" />
                                </div>
                            </div>

                            <Modal isOpen={this.state.modalPrivacyOpen} size="lg" >
                                <ModalHeader toggle={this.togglePrivacyModal}>
                                    <FormattedMessage id="user.signup-privacy-title" />
                                </ModalHeader>
                                <ModalBody>
                                    <PrivacyComponent />
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="primary" onClick={this.togglePrivacyModal}>
                                        <FormattedMessage id="general.close" />
                                    </Button>
                                </ModalFooter>
                            </Modal>

                            <Modal isOpen={this.state.modalTermsOpen}>
                                <ModalHeader toggle={this.toggleTermsModal}>
                                    <FormattedMessage id="user.signup-terms-title" />
                                </ModalHeader>
                                <ModalBody>Stuff and things</ModalBody>
                                <ModalFooter>
                                    <Button color="primary" onClick={this.toggleTermsModal}>
                                        <FormattedMessage id="general.close" />
                                    </Button>
                                </ModalFooter>
                            </Modal>

                        </ModalBody>

                        <ModalFooter>
                            <button
                                className="btn btn-pastel-orange"
                                onClick={this.props.toggle}
                            >
                                <IntlMessages id="general.cancel"/>
                            </button>
                            <button className="btn" type="submit" disabled={!isValid || !touched}>
                                <IntlMessages id="general.submit"/>
                            </button>{" "}
                        </ModalFooter>

                    </Form>
                )}
            </Formik>
        )
    }
}

LoginForm.propTypes = {
    state: PropTypes.object.isRequired,
    toggle: PropTypes.func.isRequired,
};

export default withRouter(injectIntl(LoginForm));
