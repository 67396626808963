//import appLocaleData from 'react-intl/locale-data/es';
import esMessages from "../locales/es-ES.json";

const EsLang = {
    messages: {
        ...esMessages
    },
    locale: 'es-ES',
//    data: appLocaleData
};
export default EsLang;