import {IntlProvider} from "react-intl";
import React, {Component, createContext, Suspense} from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import HomePage from "./pages/HomePage";
import NotFoundPage from "./pages/NotFoundPage";
import ContactPage from "./pages/ContactPage";
import PhotographerDetailPage from "./pages/PhotographerDetailPage";
import UnderConstructionPage from "./pages/UnderConstructionPage";
import './App.css';
import MaintenancePage from "./pages/MaintenancePage";
import UnavailablePage from "./pages/UnavailablePage";
import ExpositionDetailPage from "./pages/ExpositionDetailPage";
import ExpositionSearchPage from "./pages/ExpositionSearchPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import ExpositionsResultPage from "./pages/ExpositionsResultPage";
import ReactGA from 'react-ga';
import ExpositionsPage from "./pages/ExpositionsPage";
import PhotographersPage from "./pages/PhotographersPage";
import ClubSearchPage from "./pages/ClubSearchPage";
import PhotographersResultPage from "./pages/PhotographersResultPage";
import ClubsResultPage from "./pages/ClubsResultPage";
import PhotographerSearchPage from "./pages/PhotographerSearchPage";
import PlaceDetailPage from "./pages/PlaceDetailPage";
import AppLocale from "./lang/AppLocale";
import * as ls from "local-storage";
import RegisterPage from "./pages/RegisterPage";
import ClubDetailPage from "./pages/ClubDetailPage";

export const HostContext = createContext({
    hostName: 'http://localhost:8888/zimbel/api/web',
});

export const UserContext = createContext({
    locale: 'fr-FR',
});

const supportedLanguage = ['en', 'es', 'fr'];

class App extends Component {

    currentAppLocale = {};
    currentLocale = 'en';

    state = {
        status: 0,
        reopeningTime: undefined,
        isLoading: true,
    };

    constructor(props) {
        super(props);
        HostContext.hostName = 'http://localhost:8888/zimbel/api/web';
        if (process.env.NODE_ENV === 'production') {
            HostContext.hostName = 'https://api.zimbel.org/api/web';
        }
        ReactGA.initialize('UA-28369550-01');

        const userLocale = ls.get('zimbel-frontend-locale');
        if (userLocale !== null) {
            let userLanguage = userLocale;
            if (supportedLanguage.indexOf(userLanguage) > -1) {
                this.currentLocale = userLanguage;
            }
        } else {
            let userLanguage = navigator.language.substring(0, 2);
            if (supportedLanguage.indexOf(userLanguage) > -1) {
                this.currentLocale = userLanguage;
            }
        }
        this.currentAppLocale = AppLocale[this.currentLocale];
        UserContext.locale = this.currentAppLocale.locale;
    }

    componentWillMount = () => {
        let that = this;
        fetch(HostContext.hostName + '/sites/status')
            .then(response => response.json())
            .then(json => {
                this.setState({
                    status: json.status,
                    reopeningTime: json.reopeningTime,
                    isLoading: false
                });
            }).catch(function(error) {
                console.log(error);
                that.setState({
                    status: 0,
                    isLoading: false
                });

                //that.props.history.push('/error500');
            });
    };

    componentDidMount() {
        //loadReCaptcha("6LepWMsUAAAAAOLMFWtvkP8cZXFf__QL_uK9_lWg");
    }

    renderSite = () => {
        switch (this.state.status) {
            case 10: //10
                return (
                    <BrowserRouter>
                        <Suspense fallback={<div>Loading...</div>}>
                            <Switch>
                                <Route exact path="/" component={HomePage}/>
                                <Route path="/clubs/search" component={ClubSearchPage}/>
                                <Route path="/clubs/result" component={ClubsResultPage}/>
                                <Route path="/clubs/:name/:id" component={ClubDetailPage}/>
                                <Route exact path="/contact-us" component={ContactPage}/>
                                <Route path="/expositions/:name/:id" component={ExpositionDetailPage}/>
                                <Route path="/expositions/search" component={ExpositionSearchPage}/>
                                <Route path="/expositions/result" component={ExpositionsResultPage}/>
                                <Route path="/expositions/:status" component={ExpositionsPage}/>
                                <Route exact path="/photographers" component={PhotographersPage}/>
                                <Route path="/photographers/search" component={PhotographerSearchPage}/>
                                <Route path="/photographers/name/:name" component={PhotographersResultPage}/>
                                <Route path="/photographers/tag/:tag_label/:tag_id" component={PhotographersResultPage}/>
                                <Route path="/photographers/:name/:id/gallery/:gallery_id" component={PhotographerDetailPage}/>
                                <Route path="/photographers/:name/:id" component={PhotographerDetailPage}/>
                                <Route exact path="/photographers/:letter" component={PhotographersResultPage}/>
                                <Route path="/places/:name/:id" component={PlaceDetailPage}/>
                                <Route path="/register/:token" component={RegisterPage}/>
                                <Route exact path="/privacy" component={PrivacyPolicyPage}/>
                                <Route component={NotFoundPage}/>
                            </Switch>
                        </Suspense>
                    </BrowserRouter>
                );
            case 20: // 20
                return (
                    <BrowserRouter>
                        <Suspense fallback={<div>Loading...</div>}>
                            <Switch>
                                <Route component={UnderConstructionPage}/>
                            </Switch>
                        </Suspense>
                    </BrowserRouter>
                );
            case 30: // 30
                return (
                    <BrowserRouter>
                        <Suspense fallback={<div>Loading...</div>}>
                            <Switch>
                                <Route component={() => <MaintenancePage reopeningTime={this.state.reopeningTime} />} />
                            </Switch>
                        </Suspense>
                    </BrowserRouter>
                );
            default:
                return (
                    <BrowserRouter>
                        <Suspense fallback={<div>Loading...</div>}>
                            <Switch>
                                <Route component={UnavailablePage}/>
                            </Switch>
                        </Suspense>
                    </BrowserRouter>
                )
        }

    };

    render() {
        return this.state.isLoading ?
            (
                <div>IS LOADING</div>
            )
            :
            (
                <IntlProvider
                    locale={this.currentAppLocale.locale}
                    messages={this.currentAppLocale.messages}
                >
                    {this.renderSite()}
                </IntlProvider>

            )
    }
}

export default App;
