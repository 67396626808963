import React from "react";
import {Link, NavLink} from "react-router-dom";
import {Modal, Button} from "reactstrap";
import IntlMessages from "../util/IntlMessages";
import SignupForm from "../forms/SignupForm";
import LoginForm from "../forms/LoginForm";
import { ToastContainer } from 'react-toastify';

class NavigationComponent extends React.Component {

    state = {
        modalSignupOpen: false,
        modalLoginOpen: false,
        currentItem: null,
    };

    toggleModalSignup = () => {
        this.setState({
            modalSignupOpen: !this.state.modalSignupOpen
        });
    };

    toggleModalLogin = () => {
        this.setState({
            modalLoginOpen: !this.state.modalLoginOpen
        });
    };

    renderOld() {
        return (
            <nav className="navbar wide bg-light navbar-expand-lg">
                <div className="container-fluid flex-row justify-content-center">
                    <div className="navbar-header">
                        <div className="navbar-brand">
                            <NavLink to={"/"}>
                                <img src="#" srcSet="/style/images/logo.png 1x, /style/images/logo@2x.png 2x" alt=""/>
                            </NavLink>
                        </div>
                        <div className="navbar-hamburger ml-auto d-lg-none d-xl-none">
                            <button className="hamburger animate" data-toggle="collapse" data-target=".navbar-collapse">
                                <span></span>
                            </button>
                        </div>
                    </div>
                    <div className="navbar-collapse collapse justify-content-between align-items-center">
                        <ul className="navbar-nav plain mx-auto text-center">
                            <li className="nav-item">
                                <Link to="/" className="nav-link">
                                    <IntlMessages id="menu.home"/>
                                </Link>
                            </li>

                            <li className="nav-item dropdown">
                                <div className="with-pointer nav-link dropdown-toggle"  role="button"
                                   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <IntlMessages id="menu.exhibitions"/>
                                </div>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <NavLink className="dropdown-item" to={"/expositions/current"}>
                                        <IntlMessages id="menu.exhibitions-current"/>
                                    </NavLink>
                                    <NavLink className="dropdown-item" to={"/expositions/upcoming"}>
                                        <IntlMessages id="menu.exhibitions-upcoming"/>
                                    </NavLink>
                                    <NavLink className="dropdown-item" to={"/expositions/ended"}>
                                        <IntlMessages id="menu.exhibitions-finished"/>
                                    </NavLink>
                                    <div className="dropdown-divider"></div>
                                    <Link to="/expositions/search" className="nav-link">
                                        <IntlMessages id="menu.exhibitions-search"/>
                                    </Link>
                                </div>
                            </li>

                            <li className="nav-item dropdown">
                                <div className="with-pointer nav-link dropdown-toggle" role="button"
                                   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <IntlMessages id="menu.photographers"/>
                                </div>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <NavLink className="dropdown-item" to={"/photographers"}>
                                        <IntlMessages id="menu.photographers-favorites"/>
                                    </NavLink>
                                    <div className="dropdown-divider"></div>
                                    <NavLink className="dropdown-item" to={"/photographers/search"}>
                                        <IntlMessages id="menu.photographers-search"/>
                                    </NavLink>
                                </div>
                            </li>

                            <li className="nav-item dropdown">
                                <div className="with-pointer nav-link dropdown-toggle" role="button"
                                   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <IntlMessages id="menu.clubs"/>
                                </div>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <NavLink className="dropdown-item" to={"/photographers"}>
                                        <IntlMessages id="menu.clubs-favorites"/>
                                    </NavLink>
                                    <div className="dropdown-divider"></div>
                                    <NavLink className="dropdown-item" to={"/clubs/search"}>
                                        <IntlMessages id="menu.clubs-search"/>
                                    </NavLink>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <span id="mettre_ici_les_boutons_de_connexion"></span>
                    <div className="social-wrapper text-right">
                        <Button className="btn btn-s btn-white shadow" onClick={this.toggleModalSignup}>
                            <IntlMessages id="user.signup"/>
                        </Button>
                        <span> </span>
                        <Button className="btn btn-s btn-lavender shadow" onClick={this.toggleModalLogin}>
                            <IntlMessages id="user.login"/>
                        </Button>
                    </div>
                </div>

                <Modal
                    isOpen={this.state.modalSignupOpen}
                    toggle={this.toggleModalSignup}
                    backdrop={true}
                    wrapClassName="modal-right"
                    style={{top: 100}}
                >
                    <SignupForm state={this.state} toggle={this.toggleModalSignup}/>
                </Modal>

                <Modal
                    isOpen={this.state.modalLoginOpen}
                    toggle={this.toggleModalLogin}
                    backdrop={true}
                    wrapClassName="modal-right"
                    style={{top: 100}}
                >
                    <LoginForm state={this.state} toggle={this.toggleModalLogin}/>
                </Modal>
            </nav>
        )
    }

    render() {
        return (
            <nav className="navbar wide wrapper transparent transparent-light bg-pastel-default navbar-expand-lg">
                <div className="container-fluid flex-row justify-content-center">
                    <div className="navbar-header">
                        <div className="navbar-brand">
                            <NavLink to={"/"}>
                                <img src="#" srcSet="/style/images/logo.png 1x, /style/images/logo@2x.png 2x" alt=""/>
                            </NavLink>
                        </div>
                        <div className="navbar-hamburger ml-auto d-lg-none d-xl-none">
                            <button className="hamburger animate" data-toggle="collapse" data-target=".navbar-collapse">
                                <span></span>
                            </button>
                        </div>
                    </div>

                    <div className="navbar-collapse collapse justify-content-between align-items-center">
                        <ul className="navbar-nav plain mx-auto text-center">
                            <li className="nav-item">
                                <Link to="/" className="nav-link">
                                    <IntlMessages id="menu.home"/>
                                </Link>
                            </li>


                            <li className="nav-item dropdown">
                                <div className="with-pointer nav-link dropdown-toggle" role="button"
                                     data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <IntlMessages id="menu.exhibitions"/>
                                </div>
                                <ul className="dropdown-menu">
                                    <li className="nav-item">
                                        <NavLink className="dropdown-item" to={"/expositions/current"}>
                                            <IntlMessages id="menu.exhibitions-current"/>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="dropdown-item" to={"/expositions/upcoming"}>
                                            <IntlMessages id="menu.exhibitions-upcoming"/>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="dropdown-item" to={"/expositions/ended"}>
                                            <IntlMessages id="menu.exhibitions-finished"/>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/expositions/search" className="nav-link">
                                            <IntlMessages id="menu.exhibitions-search"/>
                                        </Link>
                                    </li>
                                </ul>
                            </li>

                            <li className="nav-item dropdown">
                                <div className="with-pointer nav-link dropdown-toggle"  role="button"
                                     data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <IntlMessages id="menu.photographers"/>
                                </div>
                                <ul className="dropdown-menu">
                                    <li className="nav-item">
                                        <NavLink className="dropdown-item" to={"/photographers"}>
                                            <IntlMessages id="menu.photographers-favorites"/>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="dropdown-item" to={"/photographers/search"}>
                                            <IntlMessages id="menu.photographers-search"/>
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>

                            <li className="nav-item dropdown">
                                <div className="with-pointer nav-link dropdown-toggle"  role="button"
                                     data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <IntlMessages id="menu.clubs"/>
                                </div>
                                <ul className="dropdown-menu">
                                    <li className="nav-item">
                                        <NavLink className="dropdown-item" to={"/photographers"}>
                                            <IntlMessages id="menu.clubs-favorites"/>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="dropdown-item" to={"/clubs/search"}>
                                            <IntlMessages id="menu.clubs-search"/>
                                        </NavLink>
                                    </li>

                                    <li className="nav-item">
                                        <NavLink className="dropdown-item" to={"/test"}>
                                            Test
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>

                        </ul>
                    </div>
                    <div className="text-right">
                        <button className="btn btn-white shadow mr-10" onClick={this.toggleModalSignup}>
                            <IntlMessages id="user.signup"/>
                        </button>
                        <button className="btn shadow" onClick={this.toggleModalLogin}>
                            <IntlMessages id="user.login"/>
                        </button>
                    </div>
                </div>

                <Modal
                    isOpen={this.state.modalSignupOpen}
                    toggle={this.toggleModalSignup}
                    backdrop={true}
                    wrapClassName="modal-right"
                    style={{top: 100}}
                >
                    <SignupForm state={this.state} toggle={this.toggleModalSignup}/>
                </Modal>

                <Modal
                    isOpen={this.state.modalLoginOpen}
                    toggle={this.toggleModalLogin}
                    backdrop={true}
                    wrapClassName="modal-right"
                    style={{top: 100}}
                >
                    <LoginForm state={this.state} toggle={this.toggleModalLogin}/>
                </Modal>

                <ToastContainer />

            </nav>
        )
    }


}

/*
            <nav className="navbar wide wrapper transparent transparent-light bg-pastel-default navbar-expand-lg">
                <div className="container-fluid flex-row justify-content-center">
                    <div className="navbar-header">
                        <div className="navbar-brand">



                    <div className="social-wrapper text-right">
                        <a href="#" className="btn btn-s btn-white shadow">S'inscrire</a>
                        <span> </span>
                        <a href="#" className="btn btn-s btn-lavender shadow">Se connecter</a>
                    </div>
 */
NavigationComponent.propTypes = {
    //exposition: PropTypes.object.isRequired,
};

export default NavigationComponent;