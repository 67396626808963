import React from "react";
import NavigationComponent from "../components/NavigationComponent";
import AlphaPagination from "../components/AlphaPagination";
import FooterComponent from "../components/FooterComponent";
import {HostContext} from "../App";
import {NavLink} from "react-router-dom";
import ReactGA from "react-ga";
import IntlMessages from "../util/IntlMessages";
import Sticky from "react-stickynode";

class PhotographersPage extends React.Component {

    state = {
        currentPage: "A",
        photographers: [],
        isLoading: false,
    };

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        fetch(HostContext.hostName + '/photographers?featured=2')
            .then(response => response.json())
            .then(json => {
                this.setState({photographers: json}, () => {
                    this.forceUpdate();
                });
            });
    }

    onChangePage = (page) => {
        this.props.history.push('/photographers/' + page);
    };

    render() {
        return (
            <div className="content-wrapper">
                <Sticky enabled={true} innerZ={999999}>
                    <NavigationComponent/>
                </Sticky>

                <div className="wrapper bg-pastel-default">
                    <div className="container inner pt-70">
                        <h1 className="heading text-center"><IntlMessages id="menu.photographers"/></h1>
                        <h2 className="sub-heading2 text-center"><IntlMessages id="menu.photographers-favorites"/></h2>
                        <div className="space50"></div>
                        <div className="tiles grid">
                            <div className="items row isotope boxed grid-view text-center">

                                {this.state.photographers.map((photographer, index) => (
                                    <div key={index} className="item grid-sizer col-md-6 col-lg-4">
                                        <div className="box bg-white shadow p-30">
                                            <figure className="main mb-20 overlay overlay1 rounded">
                                                <NavLink to={`photographers/${photographer.name_url}/${photographer.id}`}>
                                                    <span className="bg"></span>
                                                    <img src={photographer.media.url} alt=""/>
                                                </NavLink>
                                                <figcaption>
                                                    <h5 className="text-uppercase from-top mb-0">{photographer.name}</h5>
                                                </figcaption>
                                            </figure>
                                            <h4 className="mb-0">{photographer.name}</h4>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>
                    </div>
                </div>

                <div className="wrapper white-wrapper">
                    <div className="inner pt-70">
                        <div className="container">
                            <h1 className="heading text-center"><IntlMessages id="photographers.discover"/></h1>
                        </div>
                        <div className="space50"></div>
                        <div className="clearfix"></div>
                        <AlphaPagination
                            currentPage={this.state.currentPage}
                            onChangePage={i => this.onChangePage(i)}
                        />
                    </div>
                </div>

                <FooterComponent/>

            </div>
        )
    }
}

export default PhotographersPage;