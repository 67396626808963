import React from "react";
import ReactGA from "react-ga";
import { ReactSVG } from 'react-svg';
import {NavLink} from "react-router-dom";
import IntlMessages from "../util/IntlMessages";

class UnderConstructionPage extends React.Component {

    componentDidMount = () => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    };

    render() {
        return (
            <div className="content-wrapper">
                <nav className="navbar center navbar-expand-lg mt-20 mb-20">
                    <div className="container flex-lg-column">
                        <div className="navbar-brand">
                            <NavLink to={"/"}>
                                <img src="#" srcSet="/style/images/logo.png 1x, /style/images/logo@2x.png 2x" alt=""/>
                            </NavLink>
                        </div>
                    </div>
                </nav>

                <div className="wrapper gray-wrapper full-height">
                    <div className="container inner">
                        <h2 className="section-title text-center mb-50">
                            <IntlMessages id="general.under-construction"/>
                        </h2>
                        <ReactSVG src="/style/images/art/under-construction.svg" />
                    </div>
                </div>
            </div>
        )
    }
}

export default UnderConstructionPage;