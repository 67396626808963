import React from "react";
import {HostContext, UserContext} from "../App";
import NavigationComponent from "../components/NavigationComponent";
import moment from "moment";
import Gallery from "react-photo-gallery";
import Carousel, {Modal, ModalGateway} from "react-images";
import FooterComponent from "../components/FooterComponent";
import ReactGA from "react-ga";
import {NavLink} from "react-router-dom";
import IntlMessages from "../util/IntlMessages";
import {injectIntl} from "react-intl";
import 'moment/locale/fr';
import 'moment/locale/es';
import Sticky from "react-stickynode";

export const FooterCount = (props) => {
    //const {messages} = props.intl;

    return (
        <span>{props.currentIndex + 1} de {props.views.length}</span>
    );
};

export const FooterCaption = (props) => {
    const { currentView } = props;

    return (
        <span>{currentView.caption}</span>
    );
};

class PhotographerDetailPage extends React.Component {

    currentGalleryID = "";
    state = {
        photographer: {
            name: '',
            civility: 0,
            country: null,
            birthdate: null,
            deathdate: null,
            biography: "",
            media: {
                url: ''
            },
            tags: [],
            galleries: []
        },
        galleryLoaded: false,
        gallery: {
            id: null,
            title: "",
            medias: []
        },
        currentImage: null,
        lightBoxIsOpen: false,
    };

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);

        fetch(HostContext.hostName + '/photographers/' + this.props.match.params.id)
            .then(response => response.json())
            .then(json => {
                this.setState({photographer: json});
            });
    }

    renderCountry = () => {
        if (this.state.photographer.country === null) {
            return
        }
        if (this.state.photographer.country.code === null){
            return
        }

        return (
            <div><IntlMessages id="photographer.country"/> {this.state.photographer.country.name}</div>
        )
    }

    renderDates = () => {
        moment.locale(UserContext.locale.substring(0, 2));
        const {messages} = this.props.intl;
        let dates = "";
        if (this.state.photographer.birthdate != null) {
            if (this.state.photographer.civility === 0) {
                dates = messages['photographer.born-man'] + ' '
            } else {
                dates = messages['photographer.born-woman'] + ' '
            }
            dates = dates.concat(moment(this.state.photographer.birthdate).format(messages['general.date-format']))
        }
        if (this.state.photographer.deathdate != null) {
            if (dates.length > 0) {
                if (this.state.photographer.civility === 0) {
                    dates = dates.concat(", " + messages['photographer.died-man'].toLowerCase() + " ")
                } else {
                    dates = dates.concat(", " + messages['photographer.died-woman'].toLowerCase() + " ")
                }
            } else {
                if (this.state.photographer.civility === 0) {
                    dates = messages['photographer.died-man'] + ' '
                } else {
                    dates = messages['photographer.died-woman'] + ' '
                }
            }
            dates = dates.concat(moment(this.state.photographer.deathdate).format('DD MMMM YYYY'))
        }
        if (dates.length > 0) {
            return (
                <div className="mt-5 mb-5 small">{dates}</div>
            )
        } else {
            return
        }
    }

    renderTags = () => {
        if (this.state.photographer.tags.length < 1) {
            return "";
        }

        return (
            <div>
                <div><IntlMessages id="photographer.tags"/></div>
                {this.state.photographer.tags.map(tag => {
                    return (
                        <NavLink to={"/photographers/tag/" + tag.label + "/" + tag.value}>
                            <button className="btn btn-tag btn-tag-light btn-tag-rounded" key={tag.value}>{tag.label}</button>
                        </NavLink>
                    )
                })}
            </div>
        )
    }

    renderSocial = () => {
        let nbLinks = 0;
        if (this.state.photographer.website !== "") {
            nbLinks++;
        }
        if (this.state.photographer.wikipedia !== "") {
            nbLinks++;
        }
        if (this.state.photographer.facebook !== "") {
            nbLinks++;
        }
        if (this.state.photographer.twitter !== "") {
            nbLinks++;
        }
        if (this.state.photographer.instagram !== "") {
            nbLinks++;
        }
        if (this.state.photographer.flickr !== "") {
            nbLinks++;
        }
        if (this.state.photographer.cinqcentpx !== "") {
            nbLinks++;
        }

        if (nbLinks < 1) {
            return
        }

        return (
            <div>
                <div><IntlMessages id="photographer.links"/></div>
                <ul className="social social-bg social-color social-bg-white">
                    {this.state.photographer.website === "" ? "" : (
                        <li>
                            <a href={this.state.photographer.website} target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-external-link"></i>
                            </a>
                        </li>
                    )}
                    {this.state.photographer.wikipedia === "" ? "" : (
                        <li>
                            <a href={this.state.photographer.wikipedia} target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-wikipedia-w"></i>
                            </a>
                        </li>
                    )}
                    {this.state.photographer.facebook === "" ? "" : (
                        <li>
                            <a href={this.state.photographer.facebook} target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-facebook-square"></i>
                            </a>
                        </li>
                    )}
                    {this.state.photographer.twitter === "" ? "" : (
                        <li>
                            <a href={this.state.photographer.twitter} target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-twitter-square"></i>
                            </a>
                        </li>
                    )}
                    {this.state.photographer.instagram === "" ? "" : (
                        <li>
                            <a href={this.state.photographer.instagram} target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-instagram"></i>
                            </a>
                        </li>
                    )}
                    {this.state.photographer.flickr === "" ? "" : (
                        <li>
                            <a href={this.state.photographer.flickr} target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-flickr"></i>
                            </a>
                        </li>
                    )}
                    {this.state.photographer.cinqcentpx === "" ? "" : (
                        <li>
                            <a href={this.state.photographer.cinqcentpx} target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-500px"></i>
                            </a>
                        </li>
                    )}
                </ul>
            </div>
        )
    };

    renderBiography = () => {
        if (this.state.photographer.biography.length < 1) {
            return
        }

        return (
            <div className="wrapper bg-pastel-default">
                <div className="container inner">
                    <h2 className="section-title text-center"><IntlMessages id="photographer.biography"/></h2>
                    <div className="space20"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12" dangerouslySetInnerHTML={{__html: this.state.photographer.biography}}></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    openLightBox = (event, { photo, index }) => {
        this.setState({
            currentImage: index,
            lightBoxIsOpen: true
        })
    };

    closeLightBox = () => {
        this.setState({
            currentImage: 0,
            lightBoxIsOpen: false
        })
    };

    renderOneGallery = () => {
        console.log(this.state.photographer.galleries);
        if (typeof this.props.match.params.gallery_id === 'undefined') {
            this.currentGalleryID = this.state.photographer.galleries[0].id;
        } else {
            this.currentGalleryID = this.props.match.params.gallery_id;
        }

        if (!this.state.galleryLoaded) {
            fetch(HostContext.hostName + '/galleries/' + this.currentGalleryID + '/medias')
                .then(response => response.json())
                .then(json => {
                    this.setState({
                        galleryLoaded: true,
                        gallery: json,
                    });
                });
        }

        return (
            <div className="wrapper gray-wrapper">
                <div className="container inner">
                    <h2 className="section-title text-center">
                        <IntlMessages id="photographer.gallery"/>
                    </h2>
                    <h3 className="text-center">{this.state.gallery.title}</h3>
                    <div className="space20"></div>
                    <div className="container">
                        <div className="mb-2">
                            <Gallery photos={this.state.gallery.medias} margin={10} onClick={this.openLightBox}/>
                            <ModalGateway>
                                {this.state.lightBoxIsOpen ? (
                                    <Modal allowFullscreen={true}
                                           onClose={this.closeLightBox}
                                           styles={{
                                               blanket: base => ({
                                                   ...base,
                                                   backgroundColor: 'rgba(0,0,0,0.85)',
                                               }),
                                               dialog: base => ({
                                                   ...base,
                                                   maxWidth: 1080,
                                               }),
                                           }}
                                    >
                                        <Carousel
                                            components={{ FooterCount, FooterCaption }}
                                            frameProps={{ autoSize: 'height' }}
                                            currentIndex={this.state.currentImage}
                                            views={this.state.gallery.medias.map(x => ({
                                                ...x,
                                                srcset: x.src,
                                                caption: this.state.photographer.name
                                            }))}
                                            formatters={{
                                                getAltText: (CommonProps) => { return this.state.photographer.name}, // // {caption} | Image {currentIndex}
                                                getNextTitle: (CommonProps) => "Suivant (fléche droite)", // Next (right arrow)
                                                getPrevTitle: (CommonProps) => "Précédent (fléche gauche)", // Previous (left arrow)
                                                getCloseLabel: (CommonProps) => "Fermer (esc)", // Close (esc)
                                                getFullscreenLabel: (CommonProps) => "Pleine écran (f)", // [Enter | Exit] fullscreen (f)
                                            }}
                                            styles={{
                                                container: base => ({
                                                    ...base,
                                                    backgroundColor: '#fafafa',
                                                    boxShadow:
                                                        '0 1px 10px -1px rgba(0,0,0,0.2), 0 0 0 1px rgba(0,0,0,0.04), 0 1px 0 rgba(0,0,0,0.04)',
                                                    padding: 10,
                                                }),
                                                footer: base => ({
                                                    ...base,
                                                    background: 'none !important',
                                                    color: '#666',
                                                    padding: 0,
                                                    paddingTop: 20,
                                                    position: 'static',

                                                    '& a': {
                                                        color: 'black',
                                                    },
                                                }),
                                                header: base => ({
                                                    ...base,
                                                    background: 'none !important',
                                                    padding: 0,
                                                    paddingBottom: 10,
                                                    position: 'static',
                                                }),
                                                headerFullscreen: base => ({
                                                    ...base,
                                                    color: '#666',

                                                    ':hover': { color: '#7498CA' },
                                                }),
                                                headerClose: base => ({
                                                    ...base,
                                                    color: '#666',

                                                    ':hover': { color: '#7498CA' },
                                                }),
                                                navigationNext: base => ({
                                                    ...base,
                                                    color: '#666',
                                                    backgroundColor: '#EFF1F5',

                                                    ':hover': {
                                                        color: '#fff',
                                                        backgroundColor: '#7498CA',
                                                    },
                                                    ':active': {
                                                        color: '#fff',
                                                        backgroundColor: '#7498CA',
                                                        transform: 'translateY(2px)',
                                                    },
                                                }),
                                                navigationPrev: base => ({
                                                    ...base,
                                                    color: '#666',
                                                    backgroundColor: '#EFF1F5',

                                                    ':hover': {
                                                        color: '#fff',
                                                        backgroundColor: '#7498CA',
                                                    },
                                                    ':active': {
                                                        color: '#fff',
                                                        backgroundColor: '#7498CA',
                                                        transform: 'translateY(2px)',
                                                    },
                                                }),
                                                view: (base, state) => ({
                                                    ...base,
                                                    maxHeight: 480,
                                                    filter: state.interactionIsIdle ? 'grayscale(100%)' : null,
                                                    paddingBottom: `${10 / 16 * 100}%`,
                                                    overflow: 'hidden',
                                                    position: 'relative',
                                                    transition: 'filter 300ms',

                                                    '& > img': {
                                                        position: 'absolute',
                                                        left: 0,
                                                        top: 0,
                                                    },
                                                }),
                                            }}
                                        />
                                    </Modal>
                                ) : null}
                            </ModalGateway>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    renderOtherGalleries = () => {
        let otherGalleries = [];
        this.state.photographer.galleries.forEach(element => {
            if (element.id !== this.currentGalleryID) {
                otherGalleries.push(element);
            }
        });

        if (otherGalleries.length < 1) {
            return
        }

        return (
            <div className="wrapper bg-pastel-default">
                <div className="container inner">
                    <h2 className="section-title text-center">
                        <IntlMessages id="photographer.other-galleries"/>
                    </h2>
                    <div className="space20"></div>

                        <div className="container tiles grid">
                            <div className="items row isotope boxed grid-view text-center">

                                {otherGalleries.map((gallery, index) => (
                                    <div key={index} className="item grid-sizer col-md-6 col-lg-4">
                                        <div className="box bg-white shadow p-30">
                                            <figure className="main mb-20 overlay overlay1 rounded">
                                                <a href={`/photographers/${this.state.photographer.name_url}/${this.state.photographer.id}/gallery/${gallery.id}`}>
                                                    <span className="bg"></span>
                                                    <img src={gallery.media.url} alt=""/>
                                                </a>
                                                <figcaption>
                                                    <h4 className="text-uppercase from-top mb-0"><IntlMessages id="photographer.view-gallery"/></h4>
                                                </figcaption>
                                            </figure>
                                            <h5 className="mb-0">{gallery.title}</h5>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>
                </div>
            </div>
        )
    };


    renderGalleries = () => {
        const nbGalleries = Object.keys(this.state.photographer.galleries).length;
        if (nbGalleries < 1) {
            return
        } else {
            return (
                <div>
                    {this.renderOneGallery()}
                    {this.renderOtherGalleries()}
                </div>
            )
        }
    };

    render() {
        return (
            <div className="content-wrapper">
                <Sticky enabled={true} innerZ={999999}>
                    <NavigationComponent/>
                </Sticky>

                <div className="wrapper bg-pastel-default pt-60">
                    <div className="container">
                        <div className="row pb-30">
                            <div className="col-sm-6">
                                <h6 className="block-title m-b-0 "><IntlMessages id="photographer.title"/></h6>
                                <h1 className="m-t-5">{this.state.photographer.name}</h1>
                                {this.renderCountry()}
                                {this.renderDates()}
                            </div>
                            <div className="col-sm-6">
                                {this.renderSocial()}
                                {this.renderTags()}
                            </div>
                        </div>
                    </div>
                </div>

                {this.renderBiography()}

                {this.renderGalleries()}

                <FooterComponent/>
            </div>
        )
    }
}

export default injectIntl(PhotographerDetailPage);