import React from "react";
import PropTypes from "prop-types";
import Moment from 'moment';
import 'moment/locale/fr';
import {NavLink} from "react-router-dom";
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import {UserContext} from "../App";
import {injectIntl} from "react-intl";

class ExpositionCardComponent extends React.Component {

    /*
    renderComment = (value) => {
        return (
            <span className="comments"><a href="#">3</a></span>
        )
    };
     */

    renderComment = (value) => {
        return (
            <span></span>
        )
    };

    render() {
        const {messages} = this.props.intl;
        Moment.locale(UserContext.locale.substring(0, 2));

        return (
            <div className="item post grid-sizer col-md-6 col-lg-4">
                <div className="box bg-white shadow p-30">
                    <figure className="main mb-30 overlay overlay1 rounded">
                        <NavLink to={`/expositions/${this.props.exposition.name_url}/${this.props.exposition.id}`}>
                            <span className="bg"></span>
                            <img src={this.props.exposition.media.url} alt=""/>
                        </NavLink>
                        <figcaption>
                            <h5 className="text-uppercase from-top mb-0">{this.props.exposition.name}</h5>
                        </figcaption>
                    </figure>
                    <div className="meta mb-10">
                        <span className="category">
                            <NavLink to={"/places/" + this.props.exposition.place.name + "/" + this.props.exposition.place.id} className="hover color">
                                {this.props.exposition.place.name}
                            </NavLink>
                        </span>
                    </div>
                    <h2 className="post-title">
                        <a href="http://google.com">{this.props.exposition.name}</a>
                    </h2>
                    <div className="post-content">
                        <HTMLEllipsis
                            unsafeHTML={this.props.exposition.message}
                            maxLine='5'
                            ellipsis='...'
                            basedOn='letters'
                        />
                    </div>
                    <hr/>
                    <div className="meta meta-footer d-flex justify-content-between mb-0">
                        <span className="date">
                            {Moment(this.props.exposition.start).format(messages["general.date-format"])}
                            &nbsp;-&nbsp;
                            {Moment(this.props.exposition.finish).format(messages["general.date-format"])}
                        </span>
                        {this.renderComment(3)}
                    </div>
                </div>
            </div>
        )
    }
}

ExpositionCardComponent.propTypes = {
    exposition: PropTypes.object.isRequired,
};

export default injectIntl(ExpositionCardComponent);

/*
                        <p dangerouslySetInnerHTML={{__html: this.props.exposition.message}}></p>

 */