import React from "react";
import {UserContext} from "../App";

class PrivacyComponent extends React.Component {

    componentDidMount() {}

    renderEnglish = () => {
        return (
            <div className="container">
                <div className="row mt-20">
                    <div className="col-sm-12">
                        <h1>ZIMBEL PRIVACY POLICY</h1>
                    </div>
                </div>

                <div className="row mt-20">
                    <div className="col-sm-12">
                        <ul>
                            <li>Nos coordonnées en tant que responsable du traitement</li>
                            <li>Informations que nous collectons et comment nous utilisons ces informations</li>
                            <li><a href="#paragraph-3">Ce que nous faisons avec vos données personnelles</a></li>
                            <li>Combien de temps les données personnelles sont-elles conservées</li>
                            <li>Mesures de sécurité que nous utilisons</li>
                            <li>Catégories de destinataires et de processeurs de données</li>
                            <li>Your rights</li>
                            <li>Confidentialité des enfants</li>
                            <li>Notre engagement</li>
                            <li>Modifications de la politique de confidentialité</li>
                            <li>Informations de contact</li>
                        </ul>
                    </div>
                </div>

                <div className="row mt-20">
                    <div className="col-sm-12">
                        <h2>1. NOS COORDONNÉES EN TANT QUE RESPONSABLE DU TRAITEMENT</h2>
                        <p>Le site web zimbel.org vous est proposé par Didier Mérat. (le "Responsable du traitement" de vos données personnelles). Par conséquent, «nous», «nous» et «nos» font référence au responsable du traitement des données.</p>
                    </div>
                </div>

                <div className="row mt-20">
                    <div className="col-sm-12">
                        <h2>2. INFORMATIONS QUE NOUS COLLECTIONS ET COMMENT NOUS UTILISONS CES INFORMATIONS</h2>
                        <p>Nous collectons certaines informations vous concernant lorsque vous nous les fournissez directement ou utilisez notre application et notre service. Nous obtenons uniquement les informations nécessaires pour vous fournir nos services.</p>
                        <p><b>Adresse e-mail</b> : Votre adresse e-mail est un identifiant unique de vous en tant qu'utilisateur dans notre système et nous permet de sécuriser vos données. Votre adresse e-mail sera également utilisée comme moyen de communication principal pour nous sur tout ce qui concerne les modifications apportées à l'application et au service, telles que la politique de confidentialité, les conditions d'utilisation ou les fonctionnalités de base de notre application ou de notre service. Nous pouvons également vous contacter occasionnellement à des fins de marketing et il sera dans notre intérêt légitime de le faire, mais vous aurez toujours la possibilité de vous retirer de ces communications marketing pour des produits et / ou services similaires à tout moment. Votre e-mail est sécurisé et nous ne l'utilisons pas pour le profilage ou le ciblage.</p>
                        <p>Certains détails techniques sont également collectés afin que notre application et notre service fonctionnent correctement. Ces données ne peuvent pas être utilisées pour vous identifier directement. Nous ferons tous les efforts raisonnables pour conserver ces données en toute sécurité.</p>
                        <p>Nous n'utilisons pas vos données à des fins de marketing.</p>
                        <p>Contenu du courrier électronique lors de l'utilisation des services Spark: nous vous permettons, ainsi qu'à vos collègues, de créer des équipes au sein du service. Il vous permet d'avoir un espace sécurisé où vous pouvez partager des informations telles que des conversations par e-mail, des brouillons d'e-mails partagés, avoir des discussions privées ou créer des liens vers des e-mails spécifiques. Ces informations sont stockées sur nos serveurs sécurisés afin de mettre les Services à votre disposition, afin que vous puissiez collaborer avec vos coéquipiers autour du courrier électronique.</p>
                        <p>Adresse IP: la fonctionnalité principale de notre produit est basée sur la connexion à Internet. C'est pourquoi notre application et notre service ne fonctionneront pas correctement sans connexion Internet. Votre adresse IP est un identifiant unique qui vous permet de vous connecter à Internet et notre service enregistrera les connexions à des fins de sécurité et de dépannage.</p>
                        <p>Jeton d'appareil APNS (Apple Push Notification Service): les notifications push vous permettent d'obtenir des mises à jour immédiates sur les nouveaux e-mails ou les commentaires de l'équipe privée dans votre boîte de réception. Vous pouvez les activer ou les désactiver lors de la configuration initiale de l'application ou ultérieurement à l'aide des préférences système de votre appareil.</p>
                        <p>Jeton d'application attribué par nous: ce jeton nous permet d'identifier votre appareil dans notre système et de résoudre les problèmes potentiels que vous pourriez rencontrer.</p>
                        <p>Appareil, version de l'application, informations sur la version iOS: nous devons disposer de ces informations pour que l'application fonctionne correctement sur votre appareil spécifique.</p>
                        <p>Informations statistiques concernant l'utilisation de l'application: afin de mieux comprendre les schémas généraux d'utilisation des applications, d'améliorer le produit et son expérience utilisateur, Spark collecte des informations statistiques générales sur l'utilisation du produit. La collecte de ces données nous aide à optimiser l'application dans les futures mises à jour et une telle utilisation n'affecte pas vos droits et libertés et ne divulgue aucune donnée personnelle de vous-même ou de vos contacts.</p>
                        <p>E-mails récemment consultés et fils de collaboration: nous avons besoin de ces informations pour vous fournir des services Spark et vos coéquipiers, tels que des discussions privées sur les e-mails, des brouillons partagés et des conversations par e-mail partagées. En collectant et en stockant ces données, nous sommes en mesure de présenter des fils de discussion de message via votre application Spark et de fournir une meilleure expérience de communication avec votre équipe.
                            Certains de vos contacts de messagerie: Spark Smart Notifications vous enverra des notifications push uniquement pour les messages importants de personnes réelles. Pour bloquer les notifications push pour les newsletters promotionnelles et les e-mails automatiques, nous devons conserver la "liste blanche" des expéditeurs pour les notifications push. Nous synchroniserons cette "liste blanche" de contacts avec notre serveur pour activer les Smart Notifications. Si vous décidez de ne pas vous</p>
                    </div>
                </div>

                <div className="row mt-20">
                    <div className="col-sm-12">
                        <h2 id="paragraph-3">3. CE QUE NOUS FAISONS AVEC VOS DONNÉES PERSONNELLES</h2>
                        Vos données personnelles sont utilisées pour vous fournir notre application et nos services, et pour améliorer le produit. Vos données personnelles ne sont pas utilisées à des fins de marketing. Nous cryptons vos e-mails, puis stockons certaines de vos données personnelles sur des serveurs sécurisés qui empêcheraient tout accès ou destruction non autorisés. Sauf si vous nous avez demandé de ne pas le faire, nous pouvons rarement vous contacter par e-mail à propos de produits et services similaires à l'application. Chaque fois que Nous vous contacterons, Nous vous donnerons toujours le droit de vous désinscrire à tout moment (voir la section «Vos droits» ci-dessous).
                        Comme indiqué dans la section 2 ci-dessus, nous ne traitons les données personnelles qu'aux fins strictement nécessaires pour vous fournir le service. Certaines des finalités du traitement des données que vous fournissez comprennent:
                        Vous fournir les services
                        Prévention de la fraude
                        Améliorer nos services
                        Vous informer de tout changement dans nos services
                    </div>
                </div>
            </div>
        )
    };

    renderFrench = () => {
        return (
            <div className="container">
                <div className="row mt-20">
                    <div className="col-sm-12">
                        <h1>POLITIQUE DE CONFIDENTIALITÉ ZIMBEL</h1>
                    </div>
                </div>

                <div className="row mt-20">
                    <div className="col-sm-12">
                        <ul>
                            <li>Nos coordonnées en tant que responsable du traitement</li>
                            <li>Informations que nous collectons et comment nous utilisons ces informations</li>
                            <li><a href="#paragraph-3">Ce que nous faisons avec vos données personnelles</a></li>
                            <li>Combien de temps les données personnelles sont-elles conservées</li>
                            <li>Mesures de sécurité que nous utilisons</li>
                            <li>Catégories de destinataires et de processeurs de données</li>
                            <li>Vos droits</li>
                            <li>Confidentialité des enfants</li>
                            <li>Notre engagement</li>
                            <li>Modifications de la politique de confidentialité</li>
                            <li>Informations de contact</li>
                        </ul>
                    </div>
                </div>

                <div className="row mt-20">
                    <div className="col-sm-12">
                        <h2>1. NOS COORDONNÉES EN TANT QUE RESPONSABLE DU TRAITEMENT</h2>
                        <p>Le site web zimbel.org vous est proposé par Didier Mérat. (le "Responsable du traitement" de vos données personnelles). Par conséquent, «nous», «nous» et «nos» font référence au responsable du traitement des données.</p>
                    </div>
                </div>

                <div className="row mt-20">
                    <div className="col-sm-12">
                        <h2>2. INFORMATIONS QUE NOUS COLLECTIONS ET COMMENT NOUS UTILISONS CES INFORMATIONS</h2>
                        <p>Nous collectons certaines informations vous concernant lorsque vous nous les fournissez directement ou utilisez notre application et notre service. Nous obtenons uniquement les informations nécessaires pour vous fournir nos services.</p>
                        <p><b>Adresse e-mail</b> : Votre adresse e-mail est un identifiant unique de vous en tant qu'utilisateur dans notre système et nous permet de sécuriser vos données. Votre adresse e-mail sera également utilisée comme moyen de communication principal pour nous sur tout ce qui concerne les modifications apportées à l'application et au service, telles que la politique de confidentialité, les conditions d'utilisation ou les fonctionnalités de base de notre application ou de notre service. Nous pouvons également vous contacter occasionnellement à des fins de marketing et il sera dans notre intérêt légitime de le faire, mais vous aurez toujours la possibilité de vous retirer de ces communications marketing pour des produits et / ou services similaires à tout moment. Votre e-mail est sécurisé et nous ne l'utilisons pas pour le profilage ou le ciblage.</p>
                        <p>Certains détails techniques sont également collectés afin que notre application et notre service fonctionnent correctement. Ces données ne peuvent pas être utilisées pour vous identifier directement. Nous ferons tous les efforts raisonnables pour conserver ces données en toute sécurité.</p>
                        <p>Nous n'utilisons pas vos données à des fins de marketing.</p>
                        <p>Contenu du courrier électronique lors de l'utilisation des services Spark: nous vous permettons, ainsi qu'à vos collègues, de créer des équipes au sein du service. Il vous permet d'avoir un espace sécurisé où vous pouvez partager des informations telles que des conversations par e-mail, des brouillons d'e-mails partagés, avoir des discussions privées ou créer des liens vers des e-mails spécifiques. Ces informations sont stockées sur nos serveurs sécurisés afin de mettre les Services à votre disposition, afin que vous puissiez collaborer avec vos coéquipiers autour du courrier électronique.</p>
                        <p>Adresse IP: la fonctionnalité principale de notre produit est basée sur la connexion à Internet. C'est pourquoi notre application et notre service ne fonctionneront pas correctement sans connexion Internet. Votre adresse IP est un identifiant unique qui vous permet de vous connecter à Internet et notre service enregistrera les connexions à des fins de sécurité et de dépannage.</p>
                        <p>Jeton d'appareil APNS (Apple Push Notification Service): les notifications push vous permettent d'obtenir des mises à jour immédiates sur les nouveaux e-mails ou les commentaires de l'équipe privée dans votre boîte de réception. Vous pouvez les activer ou les désactiver lors de la configuration initiale de l'application ou ultérieurement à l'aide des préférences système de votre appareil.</p>
                        <p>Jeton d'application attribué par nous: ce jeton nous permet d'identifier votre appareil dans notre système et de résoudre les problèmes potentiels que vous pourriez rencontrer.</p>
                        <p>Appareil, version de l'application, informations sur la version iOS: nous devons disposer de ces informations pour que l'application fonctionne correctement sur votre appareil spécifique.</p>
                        <p>Informations statistiques concernant l'utilisation de l'application: afin de mieux comprendre les schémas généraux d'utilisation des applications, d'améliorer le produit et son expérience utilisateur, Spark collecte des informations statistiques générales sur l'utilisation du produit. La collecte de ces données nous aide à optimiser l'application dans les futures mises à jour et une telle utilisation n'affecte pas vos droits et libertés et ne divulgue aucune donnée personnelle de vous-même ou de vos contacts.</p>
                        <p>E-mails récemment consultés et fils de collaboration: nous avons besoin de ces informations pour vous fournir des services Spark et vos coéquipiers, tels que des discussions privées sur les e-mails, des brouillons partagés et des conversations par e-mail partagées. En collectant et en stockant ces données, nous sommes en mesure de présenter des fils de discussion de message via votre application Spark et de fournir une meilleure expérience de communication avec votre équipe.
                            Certains de vos contacts de messagerie: Spark Smart Notifications vous enverra des notifications push uniquement pour les messages importants de personnes réelles. Pour bloquer les notifications push pour les newsletters promotionnelles et les e-mails automatiques, nous devons conserver la "liste blanche" des expéditeurs pour les notifications push. Nous synchroniserons cette "liste blanche" de contacts avec notre serveur pour activer les Smart Notifications. Si vous décidez de ne pas vous</p>
                    </div>
                </div>

                <div className="row mt-20">
                    <div className="col-sm-12">
                        <h2 id="paragraph-3">3. CE QUE NOUS FAISONS AVEC VOS DONNÉES PERSONNELLES</h2>
                        Vos données personnelles sont utilisées pour vous fournir notre application et nos services, et pour améliorer le produit. Vos données personnelles ne sont pas utilisées à des fins de marketing. Nous cryptons vos e-mails, puis stockons certaines de vos données personnelles sur des serveurs sécurisés qui empêcheraient tout accès ou destruction non autorisés. Sauf si vous nous avez demandé de ne pas le faire, nous pouvons rarement vous contacter par e-mail à propos de produits et services similaires à l'application. Chaque fois que Nous vous contacterons, Nous vous donnerons toujours le droit de vous désinscrire à tout moment (voir la section «Vos droits» ci-dessous).
                        Comme indiqué dans la section 2 ci-dessus, nous ne traitons les données personnelles qu'aux fins strictement nécessaires pour vous fournir le service. Certaines des finalités du traitement des données que vous fournissez comprennent:
                        Vous fournir les services
                        Prévention de la fraude
                        Améliorer nos services
                        Vous informer de tout changement dans nos services
                    </div>
                </div>
            </div>
        )
    };

    renderSpanish = () => {
        return (
            <div>
                Spanish
            </div>
        )
    };

    render = () => {
        console.log(UserContext.locale);
        switch (UserContext.locale) {
            case 'fr-FR':
                return (this.renderFrench());
            case 'es-SP':
                return (this.renderSpanish());
            default:
                return (this.renderEnglish())
        }
    };
}

export default PrivacyComponent;
