import React from "react";
import {HostContext} from "../App";
import NavigationComponent from "../components/NavigationComponent";
import AlphaPagination from "../components/AlphaPagination";
import FooterComponent from "../components/FooterComponent";
import {NavLink} from "react-router-dom";
import ReactGA from "react-ga";
import IntlMessages from "../util/IntlMessages";
import {injectIntl} from "react-intl";
import {ReactSVG} from "react-svg";
import {css} from "glamor";

class PhotographersResultPage extends React.Component {

    state = {
        title: "",
        currentPage: "A",
        photographers: [],
        isLoading: false,
    };

    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
        this.loadData(this.props.match.params);
    }

    loadData(params) {
        const {messages} = this.props.intl;

        if (typeof(params.tag_id) !== "undefined") {
            fetch(HostContext.hostName + '/photographers?tag=' + params.tag_id)
                .then(response => response.json())
                .then(json => {
                    console.log(json);
                    this.setState({
                        title: messages['photographers.with-tag'] + ' ' + params.tag_label,
                        photographers: json
                    });
                });
        } else if (typeof(params.name) !== "undefined") {
            fetch(HostContext.hostName + '/photographers?search=' + params.name)
                .then(response => response.json())
                .then(json => {
                    console.log(json);
                    this.setState({
                        title: messages['photographers.with-name'] + ' ' + params.name,
                        photographers: json
                    });
                });
        } else {
            fetch(HostContext.hostName + '/photographers?selection=' + params.letter)
                .then(response => response.json())
                .then(json => {
                    console.log(json);
                    this.setState({
                        title: messages['photographers.name-begin'] + ' ' + params.letter,
                        currentPage: params.letter,
                        photographers: json
                    });
                });
        }
    }

    componentWillReceiveProps(nextProps) {
        this.loadData(nextProps.match.params);
    }

    onChangePage = (page) => {
        this.props.history.push('/photographers/' + page);
    };

    renderResult = () => {
        if (this.state.photographers.length < 1) {
            const styles = css({
                ' svg': {
                    height: 400,
                },
            });

            return (
                <div className="tiles grid">
                    <div className="row mb-50 border-top pt-20">
                        <div className="col-12">
                            <div className="text-center">
                                <p className="font-weight-bold"><IntlMessages id="photographers.none"/></p>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <ReactSVG src="/style/images/art/photographer.svg" {...styles} />
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="tiles grid">
                <div className="items row isotope boxed grid-view text-center">

                    {this.state.photographers.map((photographer, index) => (
                        <div key={index} className="item grid-sizer col-md-6 col-lg-4">
                            <div className="box bg-white shadow p-30">
                                <figure className="main mb-20 overlay overlay1 rounded">
                                    <NavLink to={`/photographers/${photographer.name_url}/${photographer.id}`}>
                                        <span className="bg"></span>
                                        <img src={photographer.media.url} alt=""/>
                                    </NavLink>
                                    <figcaption>
                                        <h5 className="text-uppercase from-top mb-0">{photographer.name}</h5>
                                    </figcaption>
                                </figure>
                                <h4 className="mb-0">{photographer.name}</h4>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        )
    };

    render() {
        return (
            <div className="content-wrapper">
                <NavigationComponent/>

                <div className="wrapper bg-pastel-default">
                    <div className="container inner pt-70">
                        <h1 className="heading text-center"><IntlMessages id="menu.photographers"/></h1>
                        <h2 className="sub-heading2 text-center">{this.state.title}</h2>
                        <div className="space50"></div>
                        {this.renderResult()}
                    </div>
                </div>

                <div className="wrapper white-wrapper">
                    <div className="inner pt-70">
                        <div className="container">
                            <h1 className="heading text-center"><IntlMessages id="photographers.discover"/></h1>
                        </div>
                        <div className="space50"></div>
                        <div className="clearfix"></div>
                        <AlphaPagination
                            currentPage={this.state.currentPage}
                            onChangePage={i => this.onChangePage(i)}
                        />
                    </div>
                </div>

                <FooterComponent/>

            </div>
        )
    }
}

export default injectIntl(PhotographersResultPage);